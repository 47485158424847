import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';
// import { runInThisContext } from 'vm';
import { Subscription } from 'rxjs';
import { IonSlides } from '@ionic/angular';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  // @ViewChild('slides', null) slides: IonSlides;
  // @ViewChild('slidesMarca', null) slidesMarca: IonSlides;

  @ViewChild('productosOfertaWeb', null) productosOfertaWeb: IonSlides;
  @ViewChild('productosOfertaMovil', null) productosOfertaMovil: IonSlides;

  @ViewChild('productosNuevosWeb', null) productosNuevosWeb: IonSlides;
  @ViewChild('productosNuevosMovil', null) productosNuevosMovil: IonSlides;

  @ViewChild('slideMarcaWeb', null) slideMarcaWeb: IonSlides;
  @ViewChild('slideMarcaMovil', null) slideMarcaMovil: IonSlides;
  ocultar: boolean = false;
  ocultarCatMovil: boolean = false;
  correoSuscripcion: string = '';
  cliente: any;
  categorias: any = [];
  marcas: any = [];
  ofertas: any = [];
  masVendidos: any = [];
  nuevos: any = [];
  responsiveOptions: any = [];
  inicioSlider: any = [];
  height: any;
  width: any;
  esMovil: boolean = true;
  suscripcion: Subscription
  sus: any = {};
  sliderMarcas = {
    slidesPerView: 3,
    initialSlide: 0,
    speed: 400,
    loop: true

  };
  sliderMarcasMovil = {
    initialSlide: 0,
    effect: 'slide',
    width: 500,
    loop: true,
    slidesPerView: 1,
    speed: 400,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        width: 320,
        loop: true,


      },
      480: {
        slidesPerView: 1,
        width: 380,
        loop: true,


      },
      720: {
        slidesPerView: 1,
        width: 350,
        loop: true,


      },
    }
  }
  sliderofertasMovil = {
    slidesPerView: 1,
    effect: 'slide',
    width: 500,
    loop: 'true',
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        width: 280,

      },
      480: {
        slidesPerView: 1,
        width: 380,

      },
      720: {
        slidesPerView: 1,
        width: 450,

      },
    }

  }
  sliderofertas = {
    slidesPerView: 4,
    initialSlide: 0,
    speed: 400,
    loop: true

  };
  sliderNuevos = {
    slidesPerView: 2,
    initialSlide: 0,
    speed: 400,
    width: 320,
    loop: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
      }
    }

  };
  //
  sliderNuevosMovil = {
    slidesPerView: 1,
    effect: 'slide',
    width: 500,
    loop: 'true',
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        width: 280,

      },
      480: {
        slidesPerView: 1,
        width: 380,

      },
      720: {
        slidesPerView: 1,
        width: 450,

      },
    }

  }
  //
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private cdRef: ChangeDetectorRef,

  ) {

    this.suscripcion = this.ls.getObservableSliderInicio().subscribe((data) => {
      this.inicioSlider = data;
      this.cdRef.detectChanges();
      //  //console.log('Data inicioSlider', this.inicioSlider);
    });


    this.cliente = {};
    this.ls.publishSomeDataNav(true);
    this.getScreenSize();
    this.carousel();
  }
  carousel() {
    this.responsiveOptions = [
      {
        breakpoint: '3480px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1280px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    // // //console.log('height: '+this.height,'width: '+ this.width);
  }

  ngOnInit() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente != undefined) {
      this.cliente = cliente;
      //console.log('this.cliente :>> ', this.cliente);
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);

    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);

    }

    // funciones de inicio
    this.getCategorias();
    this.getListaMarcasCli();
    this.getListaProductosMasVendidos();
    this.getListaProductosNuevos()
    this.inicioSlider = this.ls.sliderInicio;
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();

  }

  getListaProductosNuevos() {
    this.servicio.getListaProductosNuevos(0, 10).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.nuevos = res['msg'];
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  getListaMarcasCli() {
    this.servicio.getListaMarcasCli().subscribe(
      res => {
        //  //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.marcas = res['msg'];
          // //console.log(this.marcas);
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getCategorias() {
    this.servicio.getListaCategoriaCli().subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.categorias = res['msg'];
          this.getProductosOferta();
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  getProductosOferta() {
    this.servicio.getListaProductosOferta().subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.ofertas = res['msg'];

        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getListaProductosMasVendidos() {
    this.servicio.getListaProductosMasVendidos().subscribe(
      res => {
        // //console.log('res mas vendidos:', res);
        if (res['errorCode'] == 0) {
          this.masVendidos = res['msg'];

        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  buscadorCategoria(id, nombre) {
    // //console.log('item :>> ', id, 'nombre:>>', nombre);
    this.nav.navigateByUrl('/buscar/' + id + '/' + nombre);
  }
  irmarcas(item) {
    this.nav.navigateByUrl('/comercio/' + item);
  }

  verMasSliderInicio(tipo, idTipo, item) {

    if (tipo == 1) {
      this.nav.navigateByUrl('/comercio/' + idTipo);
    } else if (tipo == 2) {
      this.nav.navigateByUrl('/detalle-producto/' + idTipo);
    } else if (tipo == 3) {
      // //console.log('item :>> ', item);
      window.open(item.url)

    }
  }

  // siguiente(s){

  //   if(s == 1){
  //     this.slidesMarca.slideNext();

  //   }else{
  //     this.slides.slideNext();

  //   }
  // }

  suscribirse() {
    if (this.sus.correo != '' && this.validarEmail(this.sus.correo) == true) {
      let obj = {};
      obj['correo'] = this.sus.correo;
      obj['nombre'] = this.sus.nombre;

      this.servicio.postInsertarSuscripcion(obj).subscribe(
        res => {
          //     //console.log('res:', res);
          if (res['errorCode'] == 0) {
            this.ls.showToast('success', 'Correcto!', 'Te suscribiste satisfactoriamente.')
            this.sus = {};
          } else if (res['errorCode'] == 2) {

          } else if (res['errorCode'] == 3) {

          }
        },
        err => {
          //console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    } else {
      this.ls.showToast('warning', 'Alerta!', 'Verifica tu correo electrónico.')
    }
  }
  validarEmail(valor) {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
      //  alert("La dirección de email " + valor + " es correcta.");
      return true
    } else {
      //  alert("La dirección de email es incorrecta.");
      return false
    }
  }
  mostrarCategoriasMovil() {
    if (this.ocultarCatMovil == false) {
      this.ocultarCatMovil = true;
    } else {
      this.ocultarCatMovil = false;

    }
  }

 ////////// 0
 slidersMarca() {
  if (this.esMovil) {
    //console.log('movil');
    this.slideMarcaMovil.slideNext();
  } else {
    //console.log('web');
    this.slideMarcaWeb.slideNext();
  }
}
AnteriosliderMarcas() {
  if (this.esMovil) {
    //console.log('movil');
    this.slideMarcaMovil.slidePrev();
  } else {
    //console.log('web');
    this.slideMarcaWeb.slidePrev();
  }
}
  ////////// 1
  sliderProdOferta() {
    if (this.esMovil) {
      //console.log('movil');
      this.productosOfertaMovil.slideNext();
    } else {
      //console.log('web');
      this.productosOfertaWeb.slideNext();
    }
  }
  AnteriorsliderProdOferta() {
    if (this.esMovil) {
      //console.log('movil');
      this.productosOfertaMovil.slidePrev();
    } else {
      //console.log('web');
      this.productosOfertaWeb.slidePrev();
    }
  }
  ////////// 2
  sliderProdNuevos() {
    if (this.esMovil) {
      //console.log('movil');
      this.productosNuevosMovil.slideNext();
    } else {
      //console.log('web');
      this.productosNuevosWeb.slideNext();
    }
  }
  AnteriorsliderProdNuevos() {
    if (this.esMovil) {
      //console.log('movil');
      this.productosNuevosMovil.slidePrev();
    } else {
      //console.log('web');
      this.productosNuevosWeb.slidePrev();
    }
  }

  test(){
    console.log('ok');
    let obj = {
      "cod_institucion": "1042"
    }
    this.servicio.postTest(obj).subscribe(
      res => {
            console.log('res:', res);
        
      },
      err => {
        console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
}
