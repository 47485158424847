import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-boton-carrito',
  templateUrl: './boton-carrito.component.html',
  styleUrls: ['./boton-carrito.component.scss']
})
export class BotonCarritoComponent implements OnInit {
  cantidad: number = 0;
  susCarrito: Subscription;

  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private cdRef: ChangeDetectorRef,

  ) { 
    this.obtenerCarritoLocal();
    this.susCarrito = this.ls.getObservableCarrito().subscribe((data) => {
      if(data == 0) {
        this.cantidad = 0;
      } else {
        this.cantidad = this.cantidad + data;
      }
      this.cdRef.detectChanges();
      // //console.log('Data cantidad', this.cantidad);
    });

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.susCarrito.unsubscribe();

  }
  obtenerCarritoLocal() {
    // existe carrito
    let carrito = JSON.parse(localStorage.getItem('ecommerce-carrito'));
    if (carrito) {
      let cant = 0;
      for (let i = 0; i < carrito.length; i++) {
        if(carrito[i].productos.length > 0) {
          cant = cant + carrito[i].productos.length;
        }
      }
      this.cantidad = cant

      // // //console.log('this.productoCarrito :', this.productoCarrito);
    } else {
      // //console.log('no existe carrito.');
      this.cantidad = 0
    }
  }

  carrito() {
    this.nav.navigateByUrl('/carrito')
  }



}
