import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descuento'
})
export class DescuentoPipe implements PipeTransform {

  transform(precio: number,descuento : number): number {
    descuento = descuento / 100;
    descuento = precio * descuento
    let a = precio - descuento
    // // //console.log('a :>> ', a);
    return a ;
  }

}
