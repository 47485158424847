import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './paginas/login/login.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { BuscarCorreoComponent } from './paginas/buscar-correo/buscar-correo.component';
import { CambiarClaveComponent } from './paginas/cambiar-clave/cambiar-clave.component';
import { ComprasRecientesComponent } from './paginas/compras-recientes/compras-recientes.component';
import { DetalleCompraComponent } from './paginas/detalle-compra/detalle-compra.component';
import { PerfilComponent } from './paginas/perfil/perfil.component';
import { DetalleProductoComponent } from './paginas/detalle-producto/detalle-producto.component';
import { ComercioComponent } from './paginas/comercio/comercio.component';
import { CarritoComponent } from './paginas/carrito/carrito.component';
import { CompraComponent } from './paginas/compra/compra.component';
import { EstadoCompraComponent } from './paginas/estado-compra/estado-compra.component';
import { RegistroClienteComponent } from './paginas/registro-cliente/registro-cliente.component';
import { ContactoComponent } from './paginas/contacto/contacto.component';
import { TerminosComponent } from './paginas/terminos/terminos.component';
import { BuscarComponent } from './paginas/buscar/buscar.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'inicio',
    component: InicioComponent,
  },
  {
    path: 'buscar-correo',
    component: BuscarCorreoComponent,
  },
  {
    path: 'cambiar-clave',
    component: CambiarClaveComponent,
  },
  {
    path: 'compras-recientes',
    component: ComprasRecientesComponent,
  },
  {
    path: 'detalle-compra/:idPedido',
    component: DetalleCompraComponent,
  },
  {
    path: 'perfil',
    component: PerfilComponent,
  },
  {
    path: 'detalle-producto/:idProducto',
    component: DetalleProductoComponent,
  },
  {
    path: 'comercio/:idMarca',
    component: ComercioComponent,
  },
  {
    path: 'carrito',
    component: CarritoComponent,
  },
  {
    path: 'compra',
    component: CompraComponent,
  },
  {
    path: 'estado-compra/:idPedido',
    component: EstadoCompraComponent,
  },
  {
    path: 'registro-cliente',
    component: RegistroClienteComponent,
  },
  {
    path: 'contacto',
    component: ContactoComponent,
  },
  {
    path: 'terminos',
    component: TerminosComponent,
  },
  {
    path: 'buscar',
    component: BuscarComponent,
  },
  {
    path: 'buscar/:idCategoria/:nombre',
    component: BuscarComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
