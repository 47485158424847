import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DescuentoPipe } from './descuento/descuento.pipe';
import { FechaPipe } from './fechas/fecha.pipe';
import { RedondeoPipe } from './redondeo/redondeo.pipe';


@NgModule({
  declarations: [
    DescuentoPipe,
    FechaPipe,
    RedondeoPipe
  ],
  exports: [
    DescuentoPipe,
    FechaPipe
    ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
