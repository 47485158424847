import { Component, ChangeDetectorRef } from '@angular/core';
import { ServicioLocalService } from './servicios/servicio-local.service';
import { Router, NavigationEnd } from '@angular/router';

declare let fbq:Function;
declare let gtag:Function;

@Component({
  selector: 'ngx-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e-commerce-web';
  boolNav: boolean = false;
  loading: any = true;

  constructor(
    private ls : ServicioLocalService,
    private router: Router,
    private cdRef: ChangeDetectorRef,

  ) {
    //console.log('inicio')
    this.ls.getObservableNav().subscribe((data) => {
      //  // //console.log('Data received', data);
      this.boolNav = data;
    });

    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
        gtag('config', 'UA-91586824-2',{'page_path' : y.url});
      }
    })
  }

  ngOnInit(): void {
    //console.log('ngOnInit')
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //console.log('ngAfterViewInit')
    setTimeout(() => {
      this.loading = false;
      this.cdRef.detectChanges();
    }, 1500);

  }
}
