import { Component, OnInit } from '@angular/core';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from 'src/app/servicios/servicio.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-registro-cliente',
  templateUrl: './registro-cliente.component.html',
  styleUrls: ['./registro-cliente.component.scss']
})
export class RegistroClienteComponent implements OnInit {
  style: any = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]
  verPass: boolean = false;
  cliente: any = {};
  uploadedFiles: any[] = [];
  msj: string ;
  loading: boolean = false;
  
  zoom: number = 15;
  lat: number ;
  lng: number ;
  markers: marker[] = [];

  constructor(
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private nav: Router,
    private alertController: AlertController
  ) {
    this.ls.publishSomeDataNav(false)

    navigator.geolocation.getCurrentPosition(pos => {
      this.lng = +pos.coords.longitude;
      this.lat = +pos.coords.latitude;
      this.markers.push({
        lat: this.lat,
        lng: this.lng,
        draggable: true,
        label: 'Tu Dirección',
      });
    },
    err => {
      // //console.log('err :>> ', err);
      this.lat = -17.783323,
      this.lng = -63.182149,
      this.markers.push({
        lat: this.lat,
        lng: this.lng,
        draggable: true,
        // label: 'Tu Dirección',
      });
    });
   }

  ngOnInit() {
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  mostrarPass() {
    if (this.verPass == false) {
      this.verPass = true;
    } else {
      this.verPass = false;
    }
    // //console.log('mostrar pass :');
  }

  registrarCliente() {
    // //console.log('this.cliente :>> ', this.cliente);
    this.cliente.lat = this.lat;
    this.cliente.lng = this.lng;
    let valido = this.validarCliente(this.cliente);
    // //console.log('valido :>> ', valido);
    if( valido == true) {
      this.loading = true;
      this.cliente.rs = 0;
      this.servicio.postInsertarCliente(this.cliente).subscribe(
        res => {
          this.loading = false;

          console.log('res :', res);
          if (res['errorCode'] == 0) {
            this.cliente = res['msg'];
            localStorage.setItem('cliente-e-commerce', JSON.stringify(this.cliente));
            this.ls.publishSomeDataCliente(this.cliente);

            let ruta = localStorage.getItem('rutaAnterior-e-commerce');
          //  //console.log('ruta :>> ', ruta);
            if(ruta) {
              this.nav.navigateByUrl(ruta);
              localStorage.removeItem('rutaAnterior-e-commerce');
            }else {
              this.nav.navigateByUrl(`/inicio`)
            }
          } else if (res['errorCode'] == 2) {
            this.ls.showToast('info','Alerta!','Verifica los campos.')
          } else if (res['errorCode'] == 3) {
            this.ls.showToast('danger','Error!','Ocurrio un error en el servicio, intenta mas tarde.')
          } else if (res['errorCode'] == 5) {
            this.existeCuenta();
          }
        },
        err => {
          console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
          this.loading = false;
          
        }
      );
    } else {
      this.msj = valido;
    }

  }

  onFileChange(event) {
    const reader: any = new FileReader();
    // // //console.log('--------------------------');
     // // //console.log(this.producto.imagen);
    if (event.target.files && event.target.files.length > 0) {
      // // //console.log('--------------');
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // //console.log(reader);
        // //console.log(this.cliente);
        if (this.cliente.imagen !== file.name) {
          this.cliente.base64 = reader.result;
          this.cliente.foto = file.name;
          // //console.log(this.cliente);
          // //console.log('file :>> ', file);
          // this.cliente.imagenP = '/assets/img/' + file.name;
        }
      };
    }
  }

  validarCliente(cliente) {
    if (this.validarEmail(cliente.correo) == false) {
      return 'Campo requerido Correo, Revisar formato del correo';
    } else if(cliente.password == undefined || cliente.password == '') {
      return 'Campo requerido Password';
    } else if(cliente.nombre == undefined || cliente.nombre == '') {
      return 'Campo requerido Nombre';
    } else if(cliente.apellido == undefined || cliente.apellido == '') {
      return 'Campo requerido Apellido';
    } else if(cliente.direccion == undefined || cliente.direccion == '') {
      return 'Campo requerido Dirección';
    } else if (cliente.ci == undefined) {
      return 'Campo requerido Nit / C.I.';
    } else if (cliente.razonSocial == undefined || cliente.razonSocial == '') {
      return 'Campo requerido Razón Social / Nombre';
    } else if(cliente.lat == undefined ) {
      return 'Campo requerido Ubicación';
    } else if(cliente.lng == undefined ) {
      return 'Campo requerido Ubicación';
    } 
    return true;
  }

  validarEmail(valor) {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
      //  alert("La dirección de email " + valor + " es correcta.");
      return true
    } else {
      //  alert("La dirección de email es incorrecta.");
      return false
    }
  }

  // ========>>>>>> GOOGLE MAPS
  clickedMarker(label: string, index: number) {
    // //console.log(`clicked the marker: ${label || index}`);
  }
  async existeCuenta() {
    const alert = await this.alertController.create({
      mode: 'ios',
      header: 'Alerta!',
      message: 'Este correo ya existe, intenta recuperar tu contraseña',
      buttons: [
        {
          text: 'Buscar Cuenta',
          handler: () => {
            console.log('Confirm Okay');
            this.nav.navigateByUrl('/buscar-correo')
          }
        }
      ]
    });
  
    await alert.present();
  }
  

  mapClicked($event: any) {
    this.markers = [];
    this.cliente.lat = $event.coords.lat;
    this.cliente.lng = $event.coords.lng;
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
      label: 'Tu Dirección',
    });
    // //console.log('this.markers :>> ', this.markers);
  }

}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;

}
