import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {

  @Input() imagenes;
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    //console.log(this.imagenes);
  }
  cerrar() {
    this.modalController.dismiss();
  }
}
