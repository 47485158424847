import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { GaleriaComponent } from '../galeria/galeria.component';


@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.scss']
})
export class DetalleProductoComponent implements OnInit {

  // @ViewChild(NgxImageGalleryComponent,{static: false}) ngxImageGallery: NgxImageGalleryComponent;
  // conf: GALLERY_CONF = {
  //   imageOffset: '0px',
  //   showDeleteControl: false,
  //   showImageTitle: false,
  // };

  cupon: string = '';
  images: any[] ;
  idProducto: number;
  producto: any = {};
  cliente: any;
  carrito: any = [];
  productoCarrito: any;
  sugeridos: any = [];
  sinProductos: boolean = true;
  loadingSugeridos: boolean = true;
  pos: number = 0;
  limite: number = 9;
  vacio: boolean = false;
  cantidadCarrito = 0;
  imagenCentral: any;
  spinnerImgen: boolean = false;
  susCantidadCarrito: Subscription;
  mostrarAlerta: number;
  objCupon: any = {};
  navegarCarrito = false;
  alertaCarrito: number = 0;
  horario: any ={};
  displayBasic2: boolean = false;


  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private activatedRoute: ActivatedRoute,
    private servicio: ServicioService,
    private location: Location,
    public modalController: ModalController

  ) {

    this.susCantidadCarrito = this.ls.getObservableCarrito().subscribe(
      res => {
        // //console.log('res :>> ', res);
        this.cantidadCarrito = res;
      }
    );

    this.getScreenSize();

    this.ls.publishSomeDataNav(true);
    this.cliente = {};
    // //console.log('constructro :>> ');

  }
  height: any;
  width: any;
  esMovil: boolean = true;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    //// //console.log('height: '+this.height,'width: '+ this.width);
  }
  cambiarFoto(e) {
    this.imagenCentral = e.target.src;
    //// //console.log(e.target.src);
  }

  back() {
    this.location.back();
  }

  ngOnInit() {
    this.idProducto = +(this.activatedRoute.snapshot.paramMap.get('idProducto'));
    // //console.log('this.idProducto :>> ', this.idProducto);
    this.images = [];
    //obtenerCarrito
    this.obtenerCarritoLocal();
    this.obtenerClienteLocal();
    this.getDetalleProducto();
    this.scrollTop();
  }
  obtenerClienteLocal() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
  }
  obtenerCarritoLocal() {
    // existe carrito
    let carrito = JSON.parse(localStorage.getItem('ecommerce-carrito'));
    if (carrito) {
      this.productoCarrito = carrito;
      // // //console.log('this.productoCarrito :', this.productoCarrito);
    } else {
      this.productoCarrito = [];
      // //console.log('no existe carrito.');
    }
  }
  getDetalleProducto() {
    this.servicio.getDetalleProducto(this.idProducto).subscribe(
      res => {
       console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.producto = res['msg'];
          this.getProductosSugeridos(this.producto.idMarca);
          this.images.push({ url: this.producto.imagen,  altText: '',title: '', thumbnailUrl: "" });
          // this.images.push({ small: this.producto.imagen,medium: this.producto.imagen,big: this.producto.imagen});

          this.imagenCentral = this.producto.imagen;
          this.producto.cantidad = 1;
          this.imagenesProducto();
          this.getHorarioAtencion();
        } else if (res['errorCode'] == 2) {
          this.ls.showToast('warning', 'Alerta!', 'No hay datos')

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );

  }
  getProductosSugeridos(idMarca) {
    this.limite = 9;
    this.pos = 0;
    this.servicio.getListaProductosMarca(idMarca, this.pos, this.limite).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let sugeridos = res['msg'];
          this.sugeridos = sugeridos.filter(item => {
            return item.idProducto != this.idProducto
          })
          this.loadingSugeridos = false;
        } else if (res['errorCode'] == 2) {
          this.ls.showToast('warning', 'Alerta!', 'No hay datos')
          this.sinProductos = false;

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  imagenesProducto() {
    this.spinnerImgen = true;
    this.servicio.getListaImagenesProductoCli(this.idProducto).subscribe(
      res => {
        this.spinnerImgen = false;
        //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let foto = res['msg']
          for (let index = 0; index < foto.length; index++) {
            this.images.push({ url: foto[index].imagen,altText: '',title: '', thumbnailUrl: "" });
          }


        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  getHorarioAtencion() {
   // //console.log('this.producto.idMarca :>> ', this.producto.idMarca);
    this.servicio.getHorarioAtencion(this.producto.idMarca).subscribe(
      res => {
        this.spinnerImgen = false;
    //    //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let horarios: any = [];
          horarios = res['msg']
          let dia = moment(new Date()).format("E")
          for (let i = 0; i < horarios.length; i++) {
            if(horarios[i].idDia == dia) {
              this.horario = horarios[i]
   //           //console.log('this.horario :>> ', this.horario);
              return;
            }
          }
          // //console.log('dia :>> ', dia);
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    )
  }

  restar() {
    if (this.producto.cantidad > 0) {
      this.producto.cantidad--;
    } else {
      // //console.log('no se puede restar.');
    }
  }
  sumar() {
    this.producto.cantidad++;
  }
  // verFotoCompleta() {
  //   // //console.log('abrir imagen');
  //   // this.ngxImageGallery.open(0);

  //   this.displayBasic2 = true;
  // }

  async verFotoCompleta() {
    const modal = await this.modalController.create({
      component: GaleriaComponent,
      cssClass: 'mimodal',
      componentProps: {
        imagenes: this.images
      },
    });
    return await modal.present();
  }

  // closeGallery() {
  //   this.ngxImageGallery.close();
  // }

  // // set new active(visible) image in gallery
  // newImage(index: number = 0) {
  //   this.ngxImageGallery.setActiveImage(index);
  // }

  // // next image in gallery
  // nextImage() {
  //   this.ngxImageGallery.next();
  // }

  // // prev image in gallery
  // prevImage() {
  //   this.ngxImageGallery.prev();
  // }


  comprarAhora() {
    // if (this.cliente.idCliente) {

      if (this.agregarCarrito(1)) {
        this.nav.navigateByUrl('/carrito')
      } else {

        this.navegarCarrito = true;
      }

    // } else {
    //   localStorage.setItem('rutaAnterior-e-commerce', this.nav.url);
    //   this.nav.navigateByUrl('/login')
    // }
  }

  navegarProducto(idProducto) {
    this.nav.navigateByUrl('/detalle-producto/' + idProducto);
    this.images = [];

    this.idProducto = idProducto;

    this.limpiarVariables();

    this.obtenerCarritoLocal();
    this.obtenerClienteLocal();
    this.getDetalleProducto();
    this.imagenesProducto();
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  limpiarVariables() {
    this.cupon = '';
    this.images = [];
    this.producto = {};
    this.cliente = {};
    this.carrito = [];
    this.productoCarrito = [];
    // this.sugeridos= [];
    this.sinProductos = true;
    this.loadingSugeridos = true;
    this.pos = 0;
    this.limite = 5;
    this.vacio = false;
    this.mostrarAlerta = 0;
    this.objCupon = {};
    this.alertaCarrito = 0;
    this.horario ={};
    this.navegarCarrito = false;

  }







  agregarCarrito(agregarAhora) {
    if (this.producto.cantidad > 0) {
      if (this.producto.stock > 0) {
        if (this.productoCarrito.length > 0) {
          if (this.productoCarrito[0].market == this.producto.market) {
            var indexSucursal: number = this.existeSucursal();
            if (this.producto.market == 2) {
              if(indexSucursal >= 0){
                if (this.productoCarrito[indexSucursal].idMarca == this.producto.idMarca) {
                  var indexProducto: number = this.existeProducto();
                  if (indexProducto >= 0) {
                    if(agregarAhora == 1) {
                      return true;
                    } else {
                      this.ls.showToast('info', 'Alerta', 'Producto ya existe en el carrito');
                      return true;
                    }
                  } else {
                    let objProducto = {};
                    objProducto['idProducto'] = this.producto.idProducto;
                    objProducto['imagen'] = this.producto.imagen;
                    objProducto['nombre'] = this.producto.nombre;
                    let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
                    objProducto['precio'] = precio;
                    objProducto['cantidad'] = this.producto.cantidad;
                    objProducto['limite'] = this.producto.limite;
                    objProducto['programar'] = this.producto.programar;

                    if (this.objCupon.idCupon) {
                      objProducto['idCupon'] = this.objCupon.idCupon;
                      objProducto['cupon'] = this.objCupon;
                    } else {
                      objProducto['idCupon'] = 0;
                    }
                    this.productoCarrito[indexSucursal]['productos'].push(objProducto);
                    localStorage.setItem("ecommerce-carrito", JSON.stringify(this.productoCarrito));
                    this.ls.publishSomeDataCarrito(1);
                    this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
                    return true;
                  }
                } else {
                  // alerta marcas diferentes en market == 2;
                  this.alertaCarrito == 3;
                  return false;
                }
              } else {
                this.alertaCarrito = 3;
              }
            } else if (this.producto.market == 1) {
              if(indexSucursal >= 0) {
                if (this.productoCarrito[indexSucursal].idMarca == this.producto.idMarca) {
                  var indexProducto: number = this.existeProducto();
                  if (indexProducto >= 0) {
                    if(agregarAhora == 1) {
                      return true;
                    } else {
                      this.ls.showToast('info', 'Alerta', 'Producto ya existe en el carrito');
                      return true;
                    }
                    return true;
                  } else {
                    let objProducto = {};
                    objProducto['idProducto'] = this.producto.idProducto;
                    objProducto['imagen'] = this.producto.imagen;
                    objProducto['nombre'] = this.producto.nombre;
                    let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
                    objProducto['precio'] = precio;
                    objProducto['cantidad'] = this.producto.cantidad;
                    objProducto['limite'] = this.producto.limite;
                    objProducto['programar'] = this.producto.programar;
                    if (this.objCupon.idCupon) {
                      objProducto['idCupon'] = this.objCupon.idCupon;
                      objProducto['cupon'] = this.objCupon;
                    } else {
                      objProducto['idCupon'] = 0;
                    }
                    this.productoCarrito[indexSucursal]['productos'].push(objProducto);
                    localStorage.setItem("ecommerce-carrito", JSON.stringify(this.productoCarrito));
                    this.ls.publishSomeDataCarrito(1);
                    this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
                    return true;
                  }
                } else /* agrega carrito y agrega producto */ {
                  let objCarrito = {};
                  objCarrito['idMarca'] = this.producto.idMarca
                  objCarrito['NombreMarcar'] = this.producto.marca
                  objCarrito['imagenMarca'] = this.producto.imagenMarca
                  objCarrito['imagenPortada'] = this.producto.imagenPortada
                  objCarrito['market'] = this.producto.market;
                  objCarrito['tipoComercio'] = this.producto.tipoComercio;

                  let objProducto = {};
                  objProducto['idProducto'] = this.producto.idProducto;
                  objProducto['imagen'] = this.producto.imagen;
                  objProducto['nombre'] = this.producto.nombre;
                  let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
                  objProducto['precio'] = precio;
                  objProducto['cantidad'] = this.producto.cantidad;
                  objProducto['limite'] = this.producto.limite;
                  objProducto['programar'] = this.producto.programar;
                  if (this.objCupon.idCupon) {
                    objProducto['idCupon'] = this.objCupon.idCupon;
                    objProducto['cupon'] = this.objCupon;
                  } else {
                    objProducto['idCupon'] = 0;
                  }
                  let array = [];
                  array.push(objProducto);
                  objCarrito['productos'] = array;
                  // //console.log('objCarrito :>> ', objCarrito);
                  this.productoCarrito.push(objCarrito);
                  localStorage.setItem('ecommerce-carrito', JSON.stringify(this.productoCarrito));
                  this.ls.publishSomeDataCarrito(1);
                  this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
                  // //console.log('agregado a carrito :>> ');
                  return true;
                }
              } else {
                let objCarrito = {};
                objCarrito['idMarca'] = this.producto.idMarca
                objCarrito['NombreMarcar'] = this.producto.marca
                objCarrito['imagenMarca'] = this.producto.imagenMarca
                objCarrito['imagenPortada'] = this.producto.imagenPortada
                objCarrito['market'] = this.producto.market;
                objCarrito['tipoComercio'] = this.producto.tipoComercio;

                let objProducto = {};
                objProducto['idProducto'] = this.producto.idProducto;
                objProducto['imagen'] = this.producto.imagen;
                objProducto['nombre'] = this.producto.nombre;
                let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
                objProducto['precio'] = precio;
                objProducto['cantidad'] = this.producto.cantidad;
                objProducto['limite'] = this.producto.limite;
                objProducto['programar'] = this.producto.programar;
                if (this.objCupon.idCupon) {
                  objProducto['idCupon'] = this.objCupon.idCupon;
                  objProducto['cupon'] = this.objCupon;
                } else {
                  objProducto['idCupon'] = 0;
                }
                let array = [];
                array.push(objProducto);
                objCarrito['productos'] = array;
                // //console.log('objCarrito :>> ', objCarrito);
                this.productoCarrito.push(objCarrito);
                localStorage.setItem('ecommerce-carrito', JSON.stringify(this.productoCarrito));
                this.ls.publishSomeDataCarrito(1);
                this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
                // //console.log('agregado a carrito :>> ');
                return true;
              }

            } else {
              // producto.market no existe. no es 1 ni 2
              this.alertaCarrito = 2;
              return false;
            }
          } else {
            //producto y carrito no son del mismo market
            this.alertaCarrito = 1;
            return false
          }

        } else {
          let objCarrito = {};
          objCarrito['idMarca'] = this.producto.idMarca
          objCarrito['NombreMarcar'] = this.producto.marca
          objCarrito['imagenMarca'] = this.producto.imagenMarca
          objCarrito['imagenPortada'] = this.producto.imagenPortada
          objCarrito['market'] = this.producto.market;
          objCarrito['tipoComercio'] = this.producto.tipoComercio;

          let objProducto = {};
          objProducto['idProducto'] = this.producto.idProducto;
          objProducto['imagen'] = this.producto.imagen;
          objProducto['nombre'] = this.producto.nombre;
          let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
          objProducto['precio'] = precio;
          objProducto['cantidad'] = this.producto.cantidad;
          objProducto['limite'] = this.producto.limite;
          objProducto['programar'] = this.producto.programar;
          if (this.objCupon.idCupon) {
            objProducto['idCupon'] = this.objCupon.idCupon;
            objProducto['cupon'] = this.objCupon;
          } else {
            objProducto['idCupon'] = 0;
          }
          let array = [];
          array.push(objProducto);
          objCarrito['productos'] = array;
          // //console.log('objCarrito :>> ', objCarrito);
          this.productoCarrito.push(objCarrito);
          localStorage.setItem('ecommerce-carrito', JSON.stringify(this.productoCarrito));
          this.ls.publishSomeDataCarrito(1);
          this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
          // //console.log('agregado a carrito :>> ');
          return true;
        }
      } else {
        this.ls.showToast('warning', 'Alerta!', 'Este producto no esta disponible.')
        return false
      }
    } else {
      // //console.log('cantidad es 0;');
      this.ls.showToast('warning', 'Alerta!', 'Cantadad de producto es 0')
      return false
    }
  }


  sobreEscribirCarrito() {
    //
    this.alertaCarrito = 0;
    this.productoCarrito = [];
    let objCarrito = {};
    objCarrito['idMarca'] = this.producto.idMarca
    objCarrito['NombreMarcar'] = this.producto.marca
    objCarrito['imagenMarca'] = this.producto.imagenMarca
    objCarrito['imagenPortada'] = this.producto.imagenPortada
    objCarrito['market'] = this.producto.market;
    objCarrito['tipoComercio'] = this.producto.tipoComercio;

    // //console.log('objCarrito :>> ', objCarrito);
    let objProducto = {};
    objProducto['idProducto'] = this.producto.idProducto;
    objProducto['imagen'] = this.producto.imagen;
    objProducto['nombre'] = this.producto.nombre;
    let precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento)
    objProducto['precio'] = precio;
    objProducto['cantidad'] = this.producto.cantidad;
    objProducto['limite'] = this.producto.limite;
    objProducto['programar'] = this.producto.programar;

    if (this.objCupon.idCupon) {
      objProducto['idCupon'] = this.objCupon.idCupon;
      objProducto['cupon'] = this.objCupon;
    } else {
      objProducto['idCupon'] = 0;
    }
    let array = [];
    array.push(objProducto);
    objCarrito['productos'] = array;
    // //console.log('objCarrito :>> ', objCarrito);
    this.productoCarrito.push(objCarrito);
    localStorage.setItem('ecommerce-carrito', JSON.stringify(this.productoCarrito));
    this.ls.publishSomeDataCarrito(0);
    this.ls.publishSomeDataCarrito(1);
    this.ls.showToast('success', 'Correcto!', 'Producto ingresado en carrito')
    // //console.log('agregado a carrito :>> ');
    if (this.navegarCarrito == true) {
      this.nav.navigateByUrl('/carrito');
    }
  }




  getDescuentoPorcentaje(precio: number, descuento: number): number {
    descuento = descuento / 100;
    descuento = precio * descuento
    let a = precio - descuento
    a =+( a.toFixed(2))
    // //console.log('a :>> ', a);
    return a;
  }
  getDescuentoMonto(precio: number, descuento: number): number {
    descuento = precio - descuento;
    return descuento;
  }

  existeSucursal() {
    var indexSucursal: number = this.productoCarrito.indexOf(this.productoCarrito.find(x => x.idMarca == this.producto['idMarca']));
    return indexSucursal;

  }
  existeProducto() {

    var indexSucursal: number = this.existeSucursal()
    if (indexSucursal >= 0) {
      var producto = this.productoCarrito[indexSucursal].productos;
      var indexProducto: number = producto.indexOf(producto.find(x => x.idProducto == this.producto['idProducto']));
      return indexProducto;
    } else {
      return -1;
    }
  }


  verificarCupon() {
    if (this.cupon.length > 0) {
      // //console.log('this.cupon :>> ', this.cupon);
      this.servicio.getVerificarCupon(this.producto.idProducto, this.cupon, this.cliente.idCliente, this.cliente.token).subscribe(
        res => {
       //   //console.log('res :', res);
          if (res['errorCode'] == 0) {
            this.objCupon = res['msg'];
            let precio: number;
            if (this.producto.descuento > 0) {
              precio = this.getDescuentoPorcentaje(this.producto.precio, this.producto.descuento);
            } else {
              precio = this.producto.precio;
            }

            if (this.objCupon.idTipoCupon == 1) {
              this.mostrarAlerta = 1;
              this.objCupon['precioProducto'] = this.getDescuentoPorcentaje(precio, this.objCupon.total);
            } else if (this.objCupon.idTipoCupon == 2) {
              this.mostrarAlerta = 3;
              this.objCupon['precioProducto'] = this.getDescuentoMonto(precio, this.objCupon.total);
            }
          } else if (res['errorCode'] == 2) {
            // this.ls.showToast('warning', 'Alerta!', 'Cupon no disponible')
            this.mostrarAlerta = 2;
          } else if (res['errorCode'] == 3) {
            this.mostrarAlerta = 2;
            // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
          } else if (res['errorCode'] == 4) {
            this.mostrarAlerta = 4;
            // this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
          }
        },
        err => {
          // //console.log('err :', err);
          this.mostrarAlerta = 2;
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    }
  }
  navegarLogin() {
  //  //console.log('this.nav :>> ', this.nav.url);
    localStorage.setItem('rutaAnterior-e-commerce', this.nav.url);
    this.nav.navigateByUrl('/login')
  }



  loadData(event) {
    this.pos = this.pos + this.limite;
    this.servicio.getListaProductosMarca(this.producto.idMarca, this.pos, this.limite).subscribe(
      res => {
        event.target.complete();
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let sugeridos = res['msg'];
          sugeridos = sugeridos.filter(item => {
            return item.idProducto != this.idProducto
          })
          for (let i = 0; i < sugeridos.length; i++) {
            this.sugeridos.push(sugeridos[i]);
          }
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )
          this.vacio = true;

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        event.target.complete();

        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );

  }
}
