import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  cliente: any;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService
  ) {
    this.ls.publishSomeDataNav(true);
    this.cliente = {};
   }
  ngOnInit() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if(cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente); 
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente); 
    }
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

}
