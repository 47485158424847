import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import * as moment from 'moment';
import { ServicioService } from '../../servicios/servicio.service';

@Component({
  selector: 'app-compras-recientes',
  templateUrl: './compras-recientes.component.html',
  styleUrls: ['./compras-recientes.component.scss']
})
export class ComprasRecientesComponent implements OnInit {
  cliente: any;
  fecha: any = {}
  limite: number = 10;
  pos: number = 0;
  height: any;
  width: any;
  esMovil: boolean = true;
  listaPedidos: any = []
  mostrarVacio: number = 4;
  nohaydatos: number = 0;
  btnActive = 0;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService
  ) {
    this.getScreenSize();

    this.cliente = {};

    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);

    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }




    this.fecha = {
      fechaFin: moment(new Date()).format("YYYY-MM-DD")
    }
    // //console.log('this.fecha :>> ', this.fecha);
    this.ls.publishSomeDataNav(true);

    if (this.ls.getComprasRecientes().length > 0) {
      this.mostrarVacio = 0;

      this.listaPedidos = this.ls.getComprasRecientes();
      this.fecha.fechaIni = this.ls.getComprasRecientesFecha().fechaIni;
      this.fecha.fechaFin = this.ls.getComprasRecientesFecha().fechaFin;
      this.limite = this.ls.getComprasRecientesFecha().limite;
      this.pos = this.ls.getComprasRecientesFecha().pos;

    } else {
      this.ultimosDias(7);

    }

  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  ngOnInit() {
    this.scrollTop();

  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  cargarFecha() {
    if (this.fecha.fechaIni && this.fecha.fechaFin) {
      this.pos = 0;
      this.limite = 10;
      let fechaIni = moment(this.fecha.fechaIni).format("YYYY-MM-DD");
      let fechaFin = moment(this.fecha.fechaFin).format("YYYY-MM-DD");
      // //console.log('this.fecha :', fechaIni, "----", fechaFin);
      // //console.log('this.cliente :>> ', this.cliente.idCliente,this.cliente.token);

      this.mostrarVacio = 1;
      this.servicio.getListaComprasRecientes(this.pos, this.limite, fechaIni, fechaFin, this.cliente.idCliente, this.cliente.token).subscribe(
        res => {
          this.mostrarVacio = 0;
          console.log('res :', res);
          if (res['errorCode'] == 0) {
            this.listaPedidos = res['msg']
            this.ls.setComprasRecientes(this.listaPedidos);
            this.ls.setComprasRecientesFecha({ fechaIni, fechaFin, limite: this.limite, pos: this.pos })

          } else if (res['errorCode'] == 2) {
            // this.mostrarAlert('Alerta!', null, 'No se encontro el usuario')
            this.mostrarVacio = 2;
          } else if (res['errorCode'] == 3) {
            this.mostrarVacio = 2;
          } else if (res['errorCode'] == 4) {
            this.mostrarVacio = 3;
          }
        },
        err => {
          //console.log('err :', err);
          this.mostrarVacio = 0;

        }
      );

    }
  }
  ultimosDias(dias) {
    if (dias == 7) {
      this.btnActive = 1
    } else if (dias == 15) {
      this.btnActive = 2

    } else {
      this.btnActive = 3

    }
    // //console.log(dias);
    let fechaActual = new Date();
    let fechaHasta = moment(fechaActual).format("YYYY-MM-DD");
    let fechaDesde = moment(fechaActual.setDate(fechaActual.getDate() - dias)).format("YYYY-MM-DD");
    this.fecha.fechaIni = fechaDesde;
    this.fecha.FechaFin = fechaHasta;
    this.cargarFecha();
  }
  buscarDias(dias) {
    // //console.log('dias :>> ', dias);
    this.fecha.fechaIni = '16-05-2020'
  }
  navegar(estado, idPedido, item) {
    // //console.log(estado+ " -- " + idPedido);
    if (estado == 4) {
      this.nav.navigateByUrl('/detalle-compra/' + idPedido)
    } else {
      this.ls.setCabeceraPedido(item);
      this.nav.navigateByUrl('/estado-compra/' + idPedido);
    }
  }
  loadDatacargarFecha(event) {
    this.nohaydatos = 0;
    this.pos = this.pos + this.limite;
    let fechaIni = moment(this.fecha.fechaIni).format("YYYY-MM-DD");
    let fechaFin = moment(this.fecha.fechaFin).format("YYYY-MM-DD");
    //  //console.log('fechaIni,fechaFin :>> ', fechaIni,fechaFin);
    //  //console.log('this.pos,this.limite :>> ', this.pos,this.limite);
    this.servicio.getListaComprasRecientes(this.pos, this.limite, fechaIni, fechaFin, this.cliente.idCliente, this.cliente.token).subscribe(
      res => {
        event.target.complete();

        this.mostrarVacio = 0;
        //     //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let listaPedidos = res['msg'];
          for (let i = 0; i < listaPedidos.length; i++) {
            this.listaPedidos.push(listaPedidos[i]);
          }
          this.ls.setComprasRecientes(this.listaPedidos);
          this.ls.setComprasRecientesFecha({ fechaIni, fechaFin, limite: this.limite, pos: this.pos })
        } else if (res['errorCode'] == 2) {
          // this.mostrarAlert('Alerta!', null, 'No se encontro el usuario')
          this.nohaydatos = 1;
        } else if (res['errorCode'] == 3) {
          this.mostrarVacio = 2;
        } else if (res['errorCode'] == 4) {
          this.mostrarVacio = 3;
        }
      },
      err => {
        event.target.complete();
        //console.log('err :', err);
      }
    );

  }



}
