import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  cliente: any;
  contacto: any = {};
  loading: boolean = false;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private location: Location

  ) {
    this.cliente = {};
    this.ls.publishSomeDataNav(true)
   }
  ngOnInit() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if(cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente); 
      this.contacto.correo = this.cliente.correo;
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente); 
    }
    this.scrollTop();
  }
  back() {
    this.location.back();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  enviarComentario() {
    // //console.log('this.contacto :>> ', this.contacto);
    if(this.contacto.correo == undefined || this.contacto.correo == '') {
      // //console.log('falta correo');
    } else if(this.contacto.comentario == undefined || this.contacto.comentario == '') {
      // //console.log('falta comentario');
    } else {
      // //console.log('llamar al servicio de contacto.');
      let obj = {};
      if(this.cliente.nombre == undefined || this.cliente.nombre == '') {
        obj['cliente'] = 'Anónimo';
      } else {
        obj['cliente'] = this.cliente.nombre;
      }
      obj['correoCli'] = this.contacto.correo ;
      obj['detalle'] = this.contacto.comentario;
      obj['asunto'] = 'Contacto desde la web, nenechavez.com';
      this.loading = true;
      this.servicio.postEnviarCorreo(obj).subscribe(
        res => {
          this.loading = false;
        //  //console.log(res);
          if (res['errorCode'] === 0) {
            this.ls.showToast('success','Correcto!' , 'Se envió el correo de contacto correctamente.')
            this.contacto.comentario = '';
          }
          if (res['errorCode'] === 2) {
            this.ls.showToast('warning','Alerta!' , 'No se envió el correo de contacto.')
          }
          if (res['errorCode'] === 3) {
            this.ls.showToast('danger','Error!' , 'Ocurrio un error en el servicio, intenta mas tarde.')

          }
        },
        err => {
      this.loading = false;
          // //console.log(err);
          // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
        },
      );
    }
  }

}
