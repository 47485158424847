import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ServicioService } from './servicio.service';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
@Injectable({
  providedIn: 'root'
})
export class ServicioLocalService {
  style: any = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]
  types: NbComponentStatus[] = [
    "primary",
    "success",
    "info",
    "warning",
    "danger"
  ];
  sliderInicio: any = [];

  CaPedido: any;
  ////
  productosRecientes: any[] = [];
  fechasRecientes: any = {};
  private mostrarNav = new Subject<any>();
  private mostrarCliente = new Subject<any>();
  private cantCarrito = new Subject<any>();
  private Buscador = new Subject<any>();
  private inicio = new Subject<any>();

  constructor(
    private toastrService: NbToastrService,
    private servicio: ServicioService
  ) {
    // //console.log('servicio :>> ');
    this.getSliderInicio();
  }

  setCabeceraPedido(pedido) {
    this.CaPedido = pedido;
  }
  getCabeceraPedido() {
    return this.CaPedido;
  }
  //NavBar
  publishSomeDataNav(data: any) {
    this.mostrarNav.next(data);
  }
  getObservableNav(): Subject<any> {
    return this.mostrarNav;
  }
  // Cliente
  publishSomeDataCliente(data: any) {
    this.mostrarCliente.next(data);
  }
  getObservableCliente(): Subject<any> {
    return this.mostrarCliente;
  }

  //carrito
  publishSomeDataCarrito(data: any) {
    this.cantCarrito.next(data);
  }
  getObservableCarrito(): Subject<any> {
    return this.cantCarrito;
  }

  //Buscador
  publishSomeDataBuscador(data: any) {
    this.Buscador.next(data);
  }
  getObservableBuscador(): Subject<any> {
    return this.Buscador;
  }

  // Servicios Globales.
  publishSomeDataSliderInicio(data: any) {
    this.inicio.next(data);
  }
  getObservableSliderInicio(): Subject<any> {
    return this.inicio;
  }


  getSliderInicio() {
    this.servicio.getAllSliderCli().subscribe(
      res => {
        // //console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          this.sliderInicio = res['msg'];
          this.publishSomeDataSliderInicio(this.sliderInicio);
        } else if (res['errorCode'] == 2) {
          // //console.log('no se encontro datos de slider inicio');
        } else if (res['errorCode'] == 3) {
          // //console.log('error en el servicio');
        }
      },
      err => {
        // //console.log('err :>> ', err);
      }
    );
  }

  ///////// compras recientes
  getComprasRecientes() {
    return this.productosRecientes;
  }
  setComprasRecientes(compras) {
    this.productosRecientes = compras;
    ////console.log('this.productosRecientes :>> ', this.productosRecientes);
  }
///-----------------
  getComprasRecientesFecha() {
    return this.fechasRecientes;
  }
  setComprasRecientesFecha(fechas) {
    this.fechasRecientes = fechas;
 //   //console.log('this.fechasRecientes :>> ', this.fechasRecientes);
  }

  //Componentes Globales
  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, `${titleContent}`, config);
  }
}
