import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';
import { Subscription } from 'rxjs';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent implements OnInit {
  @ViewChild('subCategoriaSlides', null) subCategoriaSlides: IonSlides;
  @ViewChild('marcasSlides', null) marcasSlides: IonSlides;

  /* 
  hidden = 0 => inicializado. / nohaydatos = 0 => inicializado.
  hidden = 1 => Todos los productos. / nohaydatos = 1 => No hay datos en Todos los productos.
  hidden = 3 => productos por Comercio. / nohaydatos = 3 => No hay datos productos por Comercio. PPM
  hidden = 4 => productos por Categoria. / nohaydatos = 4 => No hay datos productos por Categoria. BC
  hidden = 5 => Productos por SubCategoria. / nohaydatos = 5 => No hay datos Productos por SubCategoria. PSC
  hidden = 7 => skeloton.
   */

  // limite posicion de Productos por Marca o comercio  PPM
  limitePPM: number = 12;
  posicionPPM: number = 0;
  productosPPM: any = [];

  // limite posicion de Productos por Sub Categoria PSC
  limitePSC = 12;
  posicionPSC = 0;
  productosPSC: any = [];
  idSubCategoria: any;
  marcasSubCat: any = [];

  productosPSCC: any = [];
  limitePSCC = 12;
  posicionPSCC = 0;
  // limite prosicion de buscador por categoria => hidden = 4
  limiteBC: number = 12;
  posicionBC: number = 0;
  productosBC: any = [];

  // limite prosicion de Productos nuevos => hidden = 1
  limiteNuevos: number = 12;
  posNuevos: number = 0;
  productos: any = [];


  // estados de vista
  hidden: number = 0;
  nohaydatos: number = 0;
  susBuscador: Subscription

  ocultarCatMovil: boolean = true;
  idCategoria: any;
  idMarca: any;
  marcas: any = [];
  subCategorias: any = [];
  cliente: any;
  text: any = '';
  tituloProducto: string = '';
  tituloMarca: string = '';
  tituloSubCategoria: string = '';

  categorias: any = [];
  mostrarFiltroMarca = 0

  height: any;
  width: any;
  esMovil: boolean = true;
  sliderMarcas = {
    slidesPerView: 4,
    initialSlide: 0,
    speed: 400,

  };
  // sliderMarcasMovil = {
  //   slidesPerView: 2,
  //   initialSlide: 0,
  //   speed: 400,

  // };
  sliderMarcasMovil = {
    initialSlide: 0,
    effect: 'slide',
    width: 500,
    slidesPerView: 2,
    speed: 400,
    breakpoints: {
      // when window width is >= 350px
      350: {
        slidesPerView: 2,
        width: 350,
      },
      480: {
        slidesPerView: 2,
        width: 380,
      },
      720: {
        slidesPerView: 2,
        width: 350,
      },
    }
  }


  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private activatedRoute: ActivatedRoute,
    private servicio: ServicioService,
    private cdRef: ChangeDetectorRef,

  ) {

    this.susBuscador = this.ls.getObservableBuscador().subscribe((data :string) => {
      //console.log('data :>> ', data);
      if(data.length == 0) {
        this.getMarcas();
        this.getBuscarProductosNuevos();
      } else {
        this.text = data;
        this.getBuscarProductoNombre();
        this.getBuscarMarcas();
        this.cdRef.detectChanges();
      }
    });

    this.cliente = {};
    this.ls.publishSomeDataNav(true);
    this.getScreenSize();

    this.idCategoria = +(this.activatedRoute.snapshot.paramMap.get('idCategoria'));
    let nombre = this.activatedRoute.snapshot.paramMap.get('nombre');
    // //console.log('nombre :>> ', nombre);

    if (this.idCategoria > 0) {
      // //console.log('this.idCategoria :>> ', this.idCategoria);
      this.categorias.idCategoria = this.idCategoria;
      // this.tituloMarca = nombre;
      // this.getComercioProCategoria(this.idCategoria);
      this.buscadorCategoria(this.idCategoria, nombre)
    } else {
      this.getMarcas();
      this.getBuscarProductosNuevos();
    }

  }

  ngOnInit() {
    this.getCategorias();
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);

    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
    this.scrollTop();
  }
  getBuscarMarcas() {
    this.servicio.getBuscarMarcaNombre(this.text).subscribe(
      res => {
        //console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          this.marcas = [];
          this.tituloProducto = '' 
          this.marcas = res['msg'];
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      }, 
      err => {
        //console.log('err :>> ', err);
      }
    )
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  getCategorias() {
    this.servicio.getListaCategoriaCli().subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.categorias = res['msg'];
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getBuscarProductoNombre() {
    this.hidden = 7;
    this.servicio.getBuscarProductoNombre(this.text).subscribe(
      res => {
        this.hidden = 1;

        // //console.log(res);
        if (res['errorCode'] === 2) {
          this.productos = [];
          // this.servicio.showToast('warning', 'Notificación', 'No se encontraron pedidos para el cliente');
        }
        if (res['errorCode'] === 0) {
          this.productos = res['msg'];
          // this.hidden = 2;
        }
      },
      err => {
        // //console.log(err);
        this.hidden = 3;

        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    );
  }

  //GET TODOS PRODUCTOS CATEGORIA hidden = 1

  getBuscarProductosNuevos() {
    this.hidden = 7;
    this.mostrarFiltroMarca = 0;
    this.limiteNuevos = 12;
    this.posNuevos = 0;
    this.servicio.getListaProductosNuevos(this.posNuevos, this.limiteNuevos).subscribe(
      res => {
        // //console.log(res);
        if (res['errorCode'] === 0) {
          this.productos = res['msg'];
          this.hidden = 1;
        }
        if (res['errorCode'] === 2) {
          this.productos = [];
          this.hidden = 2;
          // this.servicio.showToast('warning', 'Notificación', 'No se encontraron pedidos para el cliente');
        }
      },
      err => {
        // //console.log(err);
        this.hidden = 2;
        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    )
  }
  loadDatagetBuscarProductosNuevos(event) {
    this.posNuevos = this.posNuevos + this.limiteNuevos;
    this.servicio.getListaProductosNuevos(this.posNuevos, this.limiteNuevos).subscribe(
      res => {
        event.target.complete();

        // //console.log(res);
        if (res['errorCode'] === 0) {
          // this.productos = res['msg'];
          // this.hidden = 1;
          let productos = res['msg'];
          for (let i = 0; i < productos.length; i++) {
            this.productos.push(productos[i]);
          }
        }
        if (res['errorCode'] === 2) {
          this.nohaydatos = 1;
          // this.servicio.showToast('warning', 'Notificación', 'No se encontraron pedidos para el cliente');
        }
      },
      err => {
        event.target.complete();
        // //console.log(err);
        this.hidden = 2;
        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    )

  }



  ver() {
    // //console.log('this.categorias.idCategoria :>> ', this.categorias.idCategoria);
  }
  cargar() {
    this.getCategorias();
  }


  getObtenerSubCategoria() {
    this.servicio.getListaSubcategoriaCli().subscribe(
      res => {
        ////console.log(res);
        if (res['errorCode'] === 0) {
          this.subCategorias = res['msg'];
          // this.hidden = 2;

        }
        if (res['errorCode'] === 2) {
          this.productos = [];
          this.subCategorias = [];

        }

      },
      err => {
        // //console.log(err);
        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    );
  }

  getMarcas() {
    this.servicio.getListaMarcasCli().subscribe(
      res => {
        ////console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.marcas = res['msg'];
          // this.hidden = 2;

        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        ////console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  //GET PRODUCTOS CATEGORIA  BC

  buscadorCategoria(idCategoria, nombre) {
    // //console.log('idCategoria :>> ', idCategoria);
    this.hidden = 1;
    this.idCategoria = idCategoria;
    this.tituloSubCategoria = nombre;
    this.tituloMarca = nombre;
    this.tituloProducto = nombre;
    // obtiene los productos por categoria
    this.getBuscarProductoCategoria(idCategoria);
    if (this.idCategoria == 3) {
      // obtener subcategorias de market
      this.getObtenerSubCategoria();
    } else {
      // obtener marcas  
      this.getComercioProCategoria(idCategoria);
    }

  }
  getBuscarProductoCategoria(idCategoria) {
    this.hidden = 7;
    this.limiteBC = 12;
    this.posicionBC = 0;
    this.nohaydatos = 0;
    this.servicio.getBuscarProductoCategoria(idCategoria, this.limiteBC, this.posicionBC).subscribe(
      res => {
        // //console.log(res);
        if (res['errorCode'] === 0) {
          this.productosBC = res['msg'];
          this.hidden = 4;
          this.mostrarFiltroMarca = 0;
        }
        if (res['errorCode'] === 2) {
          this.productos = [];
          this.mostrarFiltroMarca = 0;
          this.hidden = 2;
          // this.servicio.showToast('warning', 'Notificación', 'No se encontraron pedidos para el cliente');
        }

      },
      err => {
        // //console.log(err);
          this.mostrarFiltroMarca = 0;
          this.hidden = 2;
        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    );
  }
  loadDatagetBuscarProductoCategoria(event) {
    this.posicionBC = this.posicionBC + this.limiteBC;
    this.servicio.getBuscarProductoCategoria(this.idCategoria, this.limiteBC, this.posicionBC).subscribe(
      res => {
        event.target.complete();

        // //console.log(res);
        if (res['errorCode'] === 0) {
          // this.productosBC = res['msg'];
          // this.hidden = 4;
          let productos = res['msg'];
          for (let i = 0; i < productos.length; i++) {
            // const element = productos[i];
            this.productosBC.push(productos[i]);
          }
        }
        if (res['errorCode'] === 2) {
          this.nohaydatos = 4;
          // this.servicio.showToast('warning', 'Notificación', 'No se encontraron pedidos para el cliente');
        }

      },
      err => {
        event.target.complete();
        // //console.log(err);
        // this.servicio.showToast('danger', 'Notificación', 'Ocurrio un error al obtener los datos');
      },
    );
  }


  //GET PRODUCTOS POR MARCA  PPM
  getProductosPorMarca(idMarca, nombre) {
    //console.log('idMarca :>> ', idMarca);
    this.limitePPM = 12;
    this.posicionPPM = 0;
    this.idMarca = idMarca;
    this.hidden = 7
    //console.log('this.idCategoria :>> ', this.idCategoria);
    if(this.idCategoria == 0 ) {
      this.servicio.getListaProductosMarca(idMarca ,this.posicionPPM, this.limitePPM).subscribe(
        res => {
          //console.log('res :', res);
          if (res['errorCode'] == 0) {
  
            this.productosPPM = res['msg'];
            this.hidden = 3;
            this.mostrarFiltroMarca = 0;
  
            this.tituloProducto = nombre;
          } else if (res['errorCode'] == 2) {
  
            this.hidden = 2
            this.mostrarFiltroMarca = 0;
  
  
          } else if (res['errorCode'] == 3) {
            this.mostrarFiltroMarca = 0;
  
          }
        },
        err => {
          //console.log('err :', err);
          this.mostrarFiltroMarca = 0;
  
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    } else {
      this.servicio.getListaProductosMarcaCat(idMarca, this.idCategoria ,this.posicionPPM, this.limitePPM).subscribe(
        res => {
          //console.log('res :', res);
          if (res['errorCode'] == 0) {
  
            this.productosPPM = res['msg'];
            this.hidden = 3;
            this.mostrarFiltroMarca = 0;
  
            this.tituloProducto = nombre;
          } else if (res['errorCode'] == 2) {
  
            this.hidden = 2
            this.mostrarFiltroMarca = 0;
  
  
          } else if (res['errorCode'] == 3) {
            this.mostrarFiltroMarca = 0;
  
          }
        },
        err => {
          //console.log('err :', err);
          this.mostrarFiltroMarca = 0;
  
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    }
  }
  loadDataGetProductosPorMarca(ev) {
    this.posicionPPM = this.posicionPPM + this.limitePPM;
    if(this.idCategoria == 0) {
      this.servicio.getListaProductosMarca(this.idMarca, this.posicionPPM, this.limitePPM).subscribe(
        res => {
          ev.target.complete();
          // //console.log('res :', res);
          if (res['errorCode'] == 0) {
  
            let productos = res['msg'];
            for (let i = 0; i < productos.length; i++) {
              this.productosPPM.push(productos[i]);
            }
  
          } else if (res['errorCode'] == 2) {
  
            // this.hidden = 2
            this.nohaydatos = 3
  
  
          } else if (res['errorCode'] == 3) {
  
          }
        },
        err => {
          ev.target.complete();
          // //console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    } else {
      this.servicio.getListaProductosMarcaCat(this.idMarca, this.idCategoria ,this.posicionPPM, this.limitePPM).subscribe(
        res => {
          ev.target.complete();
          // //console.log('res :', res);
          if (res['errorCode'] == 0) {
  
            let productos = res['msg'];
            for (let i = 0; i < productos.length; i++) {
              this.productosPPM.push(productos[i]);
            }
  
          } else if (res['errorCode'] == 2) {
  
            // this.hidden = 2
            this.nohaydatos = 3
  
  
          } else if (res['errorCode'] == 3) {
  
          }
        },
        err => {
          ev.target.complete();
          // //console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    }


  }
  //////////////////////////////////////

  //GET PRODUCTOS POR SUB CATEGORIA PSC
  getProductosPorSubCategoria(idSubCategoria, nombre) {
    this.hidden = 7
    this.mostrarFiltroMarca = 0;
    this.nohaydatos = 0;
    this.tituloProducto = nombre;
    this.limitePSC = 12;
    this.posicionPSC = 0;
    this.idSubCategoria = idSubCategoria;
    this.servicio.getListaProductoSubcategoria(idSubCategoria, this.limitePSC, this.posicionPSC).subscribe(
      res => {
        ////console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.productosPSC = res['msg'];
          this.hidden = 5;
          this.mostrarFiltroMarca = 1;
          
          this.getMarcasSubCategoria();
        } else if (res['errorCode'] == 2) {

          this.hidden = 2
          this.mostrarFiltroMarca = 1;


        } else if (res['errorCode'] == 3) {
          this.mostrarFiltroMarca = 1;

        }
      },
      err => {
          this.mostrarFiltroMarca = 1;
          // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getMarcasSubCategoria() {
    
    this.servicio.getListaMarcaSubCli(this.idSubCategoria).subscribe(
      res => {
        ////console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.marcasSubCat = res['msg']
        } else if (res['errorCode'] == 2) {

          // this.hidden = 2

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getProductosPorMarcaYsubCat(idMarca){
    this.hidden = 9
    this.nohaydatos = 0;
    this.servicio.getListaProductoMarcaSubCli(this.idSubCategoria,idMarca).subscribe(
      res => {
        ////console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.productosPSCC = res['msg'];

          this.hidden = 6;
          this.mostrarFiltroMarca = 1;

        } else if (res['errorCode'] == 2) {

          this.hidden = 2
          this.mostrarFiltroMarca = 1;

        } else if (res['errorCode'] == 3) {
          // this.hidden = 2
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        this.mostrarFiltroMarca = 1;

        }
      },
      err => {
          this.mostrarFiltroMarca = 1;
          //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  loadDatagetProductosPorSubCategoria(event) {
    this.posicionPSC = this.posicionPSC + this.limitePSC;
    this.nohaydatos = 0;
    this.servicio.getListaProductoSubcategoria(this.idSubCategoria, this.limitePSC, this.posicionPSC).subscribe(
      res => {
        event.target.complete();
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          let productos = res['msg'];
          for (let i = 0; i < productos.length; i++) {
            this.productosPSC.push(productos[i]);
          }
        } else if (res['errorCode'] == 2) {

          this.nohaydatos = 5

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        event.target.complete();
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );

  }
  ////////////////////////////////////////


  getComercioProCategoria(idCategoria) {
    this.servicio.getListaMarcaCatCli(idCategoria).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {

          this.marcas = res['msg'];
          // this.hidden = 2
        } else if (res['errorCode'] == 2) {

          // this.hidden = 3

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }


  mostrarCatMovl() {
    if (this.ocultarCatMovil == false) {
      this.ocultarCatMovil = true;
    } else {
      this.ocultarCatMovil = false;
    }
  }
  slidesMarca() {
    if (this.esMovil) {
      //console.log('movil');
      this.marcasSlides.slideNext();
    } else {
      //console.log('web');
      this.marcasSlides.slideNext();
    }
  }
  anteriorSlidesMarca() {
    if (this.esMovil) {
      //console.log('movil');
      this.marcasSlides.slidePrev();
    } else {
      //console.log('web');
      this.marcasSlides.slidePrev();
    }
  }

  slidesSubCategoria() {
    if (this.esMovil) {
      //console.log('movil');
      this.subCategoriaSlides.slideNext();
    } else {
      //console.log('web');
      this.subCategoriaSlides.slideNext();
    }
  }
  anteriorSlidesSubCategoria() {
    if (this.esMovil) {
      //console.log('movil');
      this.subCategoriaSlides.slidePrev();
    } else {
      //console.log('web');
      this.subCategoriaSlides.slidePrev();
    }
  }





  // al salir del componente
  ngOnDestroy(): void {
    this.susBuscador.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    // // //console.log('height: '+this.height,'width: '+ this.width);
  }
}

