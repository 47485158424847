import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-retraso',
  templateUrl: './popover-retraso.component.html',
  styleUrls: ['./popover-retraso.component.scss']
})
export class PopoverRetrasoComponent implements OnInit {

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }

  cerrar() {
    this.popoverController.dismiss();
  }

}
