import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { Router } from '@angular/router';
import { ServicioService } from '../../servicios/servicio.service';
import { Location } from '@angular/common';
declare var google;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  style: any = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]
  mostrarTexto: boolean = false;
  loadingFoto: boolean = false;
  loadingPerfil: boolean = false;
  zoom: number = 12;
  lat: number = -17.783306;
  lng: number = -63.182127;
  markers: marker[] = [];
  actualizar: boolean = true;
  cliente: any;
  foto: any = {};
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private location: Location

  ) {
    this.ls.publishSomeDataNav(true);
    this.cliente = {};
    // this.foto.base64='../../../assets/images/user.jpg';
  }
  ngOnInit() {

    this.obtenerClienteLocal();
    this.scrollTop();
  }
  back() {
    this.location.back();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  onFileChange(event) {
    const reader: any = new FileReader();
    // // //console.log('--------------------------');
    // // //console.log(this.producto.imagen);
    if (event.target.files && event.target.files.length > 0) {
      // // //console.log('--------------');
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // //console.log(reader);
        // //console.log(this.foto);
        if (this.foto.imagen !== file.name) {
          this.foto.base64 = reader.result;
          this.foto.foto = file.name;
          // //console.log(this.foto);
          // //console.log('file :>> ', file);
          // this.cliente.imagenP = '/assets/img/' + file.name;
          // //console.log('this.foto :>> ', this.foto);
          this.modificarFotoCliente();
        }
      };
    }
  }
  botonInputFile() {
    var element = <HTMLInputElement>document.getElementById('imagenP');
    element.click();
  }
  obtenerClienteLocal() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      this.foto.base64 = this.cliente.foto;
      // //console.log('this.foto :>> ', this.foto);
      if (this.cliente.lat && this.cliente.lng) {
        this.markers = [];
        this.markers.push({
          lat: +this.cliente.lat,
          lng: +this.cliente.lng,
          draggable: true,
          label: 'Tu Dirección',
        });
        console.log('this.cliente :>> ', this.cliente);
        this.ls.publishSomeDataCliente(this.cliente);
        // //console.log('this.marker :>> ', this.markers);
      } else {
        console.log('navigator :>> ', navigator);
        if (navigator) {
          navigator.geolocation.getCurrentPosition(pos => {
            this.lng = +pos.coords.longitude;
            this.lat = +pos.coords.latitude;
            this.cliente.lat = this.lat;
            this.cliente.lng = this.lng;
            console.log('this.cliente :>> ', this.cliente);
            this.markers.push({
              lat: this.lat,
              lng: this.lng,
              draggable: true,
              label: 'Tu Dirección',
            });
          }, err => {
            console.log('err :>> ', err);
            if (err.code == 1) {
              this.ls.showToast('warning', 'Alerta!', 'El navegador no tiene permiso para obterner tu ubicación')
            }
          });
        }
      }

    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
  }
  verActualizar() {
    if (this.actualizar == true) {
      this.actualizar = false;
      this.mostrarTexto = false;

    } else {
      this.actualizar = true;
    }
  }

  actualizarDatosCliente() {
    this.cliente.idtoken = this.cliente.idCliente;
    console.log('this.cliente :>> ', this.cliente);
    this.loadingPerfil = true;
    this.servicio.postModificarCliente(this.cliente).subscribe(
      res => {
        console.log('res :', res);
        this.loadingPerfil = false;

        if (res['errorCode'] == 0) {
          this.actualizar = true;
          this.ls.showToast('success', 'Correcto!', 'Registrado con Exito')
          localStorage.setItem('cliente-e-commerce', JSON.stringify(this.cliente));


        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        this.loadingPerfil = false;

        console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  modificarFotoCliente() {
    let obj = {};
    obj['idtoken'] = this.cliente.idCliente;
    obj['token'] = this.cliente.token;
    obj['idCliente'] = this.cliente.idCliente;
    obj['base64'] = this.foto.base64;
    obj['foto'] = this.foto.foto;
    //console.log('obj :>> ', obj);
    this.loadingFoto = true;
    this.servicio.postModificarFotoCliente(obj).subscribe(
      res => {
        this.loadingFoto = false;

        //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.actualizar = true;
          this.cliente.foto = res['img'];
          localStorage.setItem('cliente-e-commerce', JSON.stringify(this.cliente));
          this.ls.publishSomeDataCliente(this.cliente)
          this.ls.showToast('success', 'Correcto!', 'Foto de Perfil actualizada con éxito')

        } else if (res['errorCode'] == 2) {
          this.ls.showToast('warning', 'Alerta!', 'No se modifico la foto de perfil')

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        this.loadingFoto = false;

        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }



  //MAPS
  obtenerUbicacion() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      });
    }
  }

  mapClicked($event: any) {
    this.markers = [];
    this.cliente.lat = $event.coords.lat;
    this.cliente.lng = $event.coords.lng;
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
      label: 'Tu Dirección',
    });
    // //console.log('this.markers :>> ', this.markers);
  }
  alerta() {
    this.mostrarTexto = true;
  }


}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;

}

