import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServicioService {
  // =========>>>>>>>>>  LOCAL
  // API_Uri = 'http://localhost/e-commerce/ecommerce/API_REST';

  // =========>>>>>>>>>  desarrollo
  API_Uri = 'https://nenechavez.com/API_REST';
  //            

  // =========>>>>>>>>>   produccion
  // API_Uri = '';

  constructor(
    private http: HttpClient,
  ) { }

  //EC01CLI - POST - LOGIN DE CLIENTE
  getLoginCliente(usuario: string, password: string) {
    return this.http.get(`${this.API_Uri}/getLoginCliente/${usuario}/${password}`);
  }
  //EC02CLI - POST - INSERTAR CLIENTE
  postInsertarCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarCliente`, obj);
  }
  //EC03CLI - GET - OBTENER LISTA DE MARCAS
  getListaMarcasCli() {
    return this.http.get(`${this.API_Uri}/getListaMarcasCli`);
  }
  //EC04CLI - GET - OBTENER LISTA DE CATEGORIAS
  getListaCategoriaCli() {
    return this.http.get(`${this.API_Uri}/getListaCategoriaCli`);
  }
  //EC04CLI - GET - OBTENER getListaMetodosPago
  getListaMetodosPago() {
    return this.http.get(`${this.API_Uri}/getListaMetodosPago`);
  }
  //EC05CLI - GET - OBTENER DETALLE DE PRODUCTO
  getDetalleProducto(idProducto) {
    return this.http.get(`${this.API_Uri}/getDetalleProducto/${idProducto}`);
  }
  //EC06CLI - GET - BUSCAR PRODUCTOS POR NOMBRE
  getBuscarProductoNombre(nombre) {
    return this.http.get(`${this.API_Uri}/getBuscarProductoNombre/${nombre}`);
  }
  //EC07CLI - GET - BUSCAR PRODUCTOS POR CATEGORIA
  getBuscarProductoCategoria(idCategoria, limite, pos) {
    return this.http.get(`${this.API_Uri}/getBuscarProductoCategoria/${idCategoria}/${limite}/${pos}`);
  }
  //EC08CLI - POST - ACTUALIZAR PASSWORD DE CLIENTE
  postUpdatePasswordCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postUpdatePasswordCliente`, obj);
  }
  //EC09CLI - GET - RECUPERAR PASSWORD
  olvidepass(obj: any) {
    return this.http.post(`${this.API_Uri}/olvidepass`, obj);
  }

  //EC10CLI - GET - VERIFICAR SI CORREO EXISTE
  existeCorreoCliente(correo) {
    return this.http.get(`${this.API_Uri}/existeCorreoCliente/${correo}`);
  }

  //EC11CLI - GET - OBTENER LISTA DE PRODUCTOS NUEVOS
  getListaProductosNuevos(pos: number, limite: number) {
    return this.http.get(`${this.API_Uri}/getListaProductosNuevos/${pos}/${limite}`);
  }
  //EC12CLI - GET - OBTENER LISTA DE PRODUCTOS MAS VENDIDOS
  getListaProductosMasVendidos() {
    return this.http.get(`${this.API_Uri}/getListaProductosMasVendidos`);
  }

  //EC13CLI - GET - OBTENER LISTA DE PRODUCTOS POR MARCA
  getListaProductosMarca(idMarca, pos, limite) {
    return this.http.get(`${this.API_Uri}/getListaProductosMarca/${idMarca}/${pos}/${limite}`);
  }
  //EC15CLI - GET - OBTENER LISTA DE COMPRAS RECIENTES
  getListaComprasRecientes(pos, limite, fechaInicio, fechaFin, idtoken, token) {
    return this.http.get(`${this.API_Uri}/getListaComprasRecientes/${pos}/${limite}/${fechaInicio}/${fechaFin}/${idtoken}/${token}`);
  }
  //EC16CLI - GET - OBTENER DETALLE DE PEDIDO
  getDetallePedidoCli(idPedido, pos, limite, idtoken, token) {
    return this.http.get(`${this.API_Uri}/getDetallePedidoCli/${idPedido}/${pos}/${limite}/${idtoken}/${token}`);
  }
  //EC17CLI - GET - OBTENER LISTA DE PRODUCTOS EN OFERTA
  getListaProductosOferta() {
    return this.http.get(`${this.API_Uri}/getListaProductosOferta`);
  }
  //EC19CLI - GET - VERIFICAR CUPON
  getVerificarCupon(idProducto, cupon, idtoken, token) {
    return this.http.get(`${this.API_Uri}/getVerificarCupon/${idProducto}/${cupon}/${idtoken}/${token}`);
  }
  //EC20CLI - GET - OBTENER LISTA DE IMAGENES DE PRODUCTO PARA CLIENTE
  getListaImagenesProductoCli(idProducto) {
    return this.http.get(`${this.API_Uri}/getListaImagenesProductoCli/${idProducto}`);
  }

  //EC21CLI - GET - OBTENER DETALLE DE MARCA
  getDetalleMarca(idMarca) {
    return this.http.get(`${this.API_Uri}/getDetalleMarca/${idMarca}`);
  }
  //IA22CLI - POST - INSERTAR PEDIDO
  postInsertarPedido(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarPedido`, obj);
  }

  //EC23CLI - GET - OBTENER PEDIDO
  getPedido(idPedido, idtoken, token) {
    return this.http.get(`${this.API_Uri}/getPedido/${idPedido}/${idtoken}/${token}`);
  }

  //EC24CLI - GET - OBTENER DETALLE DE PEDIDO POR MARCA
  getDetallePedidoMarcaCli(idPedido, idtoken, token) {
    return this.http.get(`${this.API_Uri}/getDetallePedidoMarcaCli/${idPedido}/${idtoken}/${token}`);
  }
  //EC25CLI - POST - MODIFICAR CLIENTE
  postModificarCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarCliente`, obj);
  }
  //EC26CLI - GET - MODIFICAR FOTO DE CLIENTE
  postModificarFotoCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarFotoCliente`, obj);
  }

  //EC28CLI - GET - OBTENER LISTA DE SLIDER
  getAllSliderCli() {
    return this.http.get(`${this.API_Uri}/getAllSliderCli`);
  }
  //EC29CLI - GET - OBTENER LISTA DE SUBCATEGORIAS
  getListaSubcategoriaCli() {
    return this.http.get(`${this.API_Uri}/getListaSubcategoriaCli`);
  }
  //EC30CLI - GET - OBTENER PRODUCTOS POR SUBCATEGORIA
  getListaProductoSubcategoria(idSubcategoria, limite, pos) {
    return this.http.get(`${this.API_Uri}/getListaProductoSubcategoria/${idSubcategoria}/${limite}/${pos}`);
  }

  //EC31CLI - GET - OBTENER LISTA DE MARCAS POR CATEGORIA
  getListaMarcaCatCli(idCategoria) {
    return this.http.get(`${this.API_Uri}/getListaMarcaCatCli/${idCategoria}`);
  }

  //EC33CLI - POST - ENVIAR CORREO
  postEnviarCorreo(obj: any) {
    return this.http.post(`${this.API_Uri}/postEnviarCorreo`, obj);
  }

  //EC34CLI - GET - OBTENER HORARIO DE ATENCION
  getHorarioAtencion(idMarca) {
    return this.http.get(`${this.API_Uri}/getHorarioAtencion/${idMarca}`);
  }
  //EC35CLI - POST - INSERTAR SUSCRIPCION
  postInsertarSuscripcion(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarSuscripcion`, obj);
  }

  //EC38CLI - GET - OBTENER PRECIO TRANSPORTE
  getPrecioTransporteMax() {
    return this.http.get(`${this.API_Uri}/getPrecioTransporteMax`);
  }
  //EC39CLI - GET - OBTENER LISTA DE MARCAS POR SUBCATEGORIA
  getListaMarcaSubCli(idSubcategoria) {
    return this.http.get(`${this.API_Uri}/getListaMarcaSubCli/${idSubcategoria}`);
  }
  //EC40CLI - GET - OBTENER LISTA DE PRODUCTOS POR SUBCATEGORIA Y MARCA
  getListaProductoMarcaSubCli(idSubcategoria, idMarca) {
    return this.http.get(`${this.API_Uri}/getListaProductoMarcaSubCli/${idSubcategoria}/${idMarca}`);
  }
  //EC41CLI - GET - BUSCAR MARCA POR NOMBRE
  getBuscarMarcaNombre(nombre) {
    return this.http.get(`${this.API_Uri}/getBuscarMarcaNombre/${nombre}`);
  }
  //EC42CLI - GET - OBTENER LISTA DE PRODUCTOS POR MARCA Y CATEGORIA
  getListaProductosMarcaCat(idMarca, idCategoria, pos, limite) {
    return this.http.get(`${this.API_Uri}/getListaProductosMarcaCat/${idMarca}/${idCategoria}/${pos}/${limite}`);
  }


//prueba de servicio
  postTest(obj: any) {
    return this.http.post(`${this.API_Uri}/setRegistrar3`, obj);
  }
  
  me_set_Autho_Ecomm(obj: any) {
    return this.http.post(`${this.API_Uri}/me_set_Autho_Ecomm`, obj);
  }


}
