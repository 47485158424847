import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';

@Component({
  selector: 'app-cambiar-clave',
  templateUrl: './cambiar-clave.component.html',
  styleUrls: ['./cambiar-clave.component.scss']
})
export class CambiarClaveComponent implements OnInit {
  clave: string = ''
  claveDuplicada: string = '';
  loading = false;
  msj: string ;
  cliente: any = {}
  verPass = false;
  verPass2 = false;

  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService
  ) {
    this.ls.publishSomeDataNav(false)
   }
  ngOnInit() {
    this.obtenerClienteLocal();
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  obtenerClienteLocal() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
  }
  cambiarPassword() {
    if(this.clave == this.claveDuplicada) {
      let obj = {} ;
      obj['password'] = this.clave;
      obj['idCliente'] = this.cliente.idCliente;
      obj['idtoken'] = this.cliente.idCliente;
      obj['token'] = this.cliente.token;
      this.loading = true;
      this.servicio.postUpdatePasswordCliente(obj).subscribe(
        res => {
          this.loading = false;
          // //console.log('res :', res);
          if (res['errorCode'] == 0) {
            this.nav.navigateByUrl('/inicio')
            this.ls.showToast('success', 'Correcto!', 'Se modificó tu Contraseña')

          } else if (res['errorCode'] == 2) {
            this.ls.showToast('warning', 'Alerta!', 'No puedes actualizar tu contraseña actual, Utiliza otra contraseña')

          } else if (res['errorCode'] == 3) {
            this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
          }
        },
        err => {
          this.loading = false;
          // //console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')

        }
      );
    } else {
      this.msj = 'Contraseña no coinciden';
    }
  }

  mostrarPass() {
    if (this.verPass == false) {
      this.verPass = true;
    } else {
      this.verPass = false;
    }
    // //console.log('mostrar pass :');
  }

  mostrarPass2() {
    if (this.verPass2 == false) {
      this.verPass2 = true;
    } else {
      this.verPass2 = false;
    }
    // //console.log('mostrar pass :');
  }

}
