import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from 'src/app/servicios/servicio.service';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  cliente: any = {};
  verPass: boolean = false;
  msj: string = '';
  loading: boolean = false;

  private user: SocialUser;
  private loggedIn: boolean;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private authService: AuthService
  ) {
    // //console.log('constructor');
    this.ls.publishSomeDataNav(false)
    this.authService.authState.subscribe((user) => {
      this.user = user;
      // //console.log('this.user :>> ', this.user);

      this.loggedIn = (user != null);
      if (this.loggedIn) {
        this.registroClienteRS(this.user);
      } else {
        //   //console.log('no redes sociales.');
      }
    },
      err => {
        //console.log('err :>> ', err);
      });
  }

  ngOnInit() {
    // //console.log('ngOnInit');

  }
  registroClienteRS(user) {
    //console.log('user :>> ', user);
    let obj = {};
    obj['rs'] = 1;
    obj['correo'] = user.email;
    obj['nombre'] = user.firstName;
    obj['apellido'] = user.lastName;
    obj['fotoURL'] = user.photoUrl;
    this.servicio.postInsertarCliente(obj).subscribe(
      res => {
        //console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          localStorage.setItem('cliente-e-commerce', JSON.stringify(res['msg']));

          this.ls.publishSomeDataCliente(res['msg']);
          let ruta = localStorage.getItem('rutaAnterior-e-commerce');
          //   //console.log('ruta :>> ', ruta);
          if (ruta) {
            this.nav.navigateByUrl(ruta);
            localStorage.removeItem('rutaAnterior-e-commerce');
          } else {
            this.nav.navigateByUrl(`/inicio`)
          }
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      }
    );
  }
  iniciarSesion() {
    // //console.log('this.login :>> ', this.cliente);
    if (this.cliente.correo != '' && this.cliente.password != '' && this.cliente.correo != undefined && this.cliente.password != undefined) {
      this.loading = true;
      this.msj = '';
      this.servicio.getLoginCliente(this.cliente.correo, this.cliente.password).subscribe(
        res => {
            //  console.log('res :', res);
          this.loading = false;

          if (res['errorCode'] == 0) {
            this.cliente = res['msg'];
            localStorage.setItem('cliente-e-commerce', JSON.stringify(this.cliente));

            let ruta = localStorage.getItem('rutaAnterior-e-commerce');
            //  //console.log('ruta :>> ', ruta);
            if (ruta) {
              this.nav.navigateByUrl(ruta);
              localStorage.removeItem('rutaAnterior-e-commerce');
            } else {
              this.nav.navigateByUrl(`/inicio`)
            }
          } else if (res['errorCode'] == 2) {
            this.msj = 'Correo o clave incorrectos. verifica tus datos y vuelve a intentarlo'
          } else if (res['errorCode'] == 3) {

          } else if (res['errorCode'] == 5) {
            this.cliente = res['msg'];
            localStorage.setItem('cliente-e-commerce', JSON.stringify(this.cliente));
            this.nav.navigateByUrl(`/cambiar-clave`)
          }
        },
        err => {
          console.log('err :', err);
          this.loading = false;

          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );

    } else {
      this.msj = 'Error en correo o password.'
    }
  }

  mostrarPass() {
    if (this.verPass == false) {
      this.verPass = true;
    } else {
      this.verPass = false;
    }
    // //console.log('mostrar pass :');
  }

  signInWithGoogle(): void {
    // //console.log('inicio con google.');
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

}
