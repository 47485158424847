import { ServicioService } from './../../servicios/servicio.service';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  height:any;
  width:any;
  esMovil:boolean=true;
  telefono: any;

  constructor(
    private servicio: ServicioService,

  ) {
    this.getScreenSize();
    this.obtenerTelefono();
  }

  ngOnInit() {
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        if(this.width<=600){
          this.esMovil=true;
        }else{
          this.esMovil=false;

        }
       // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  obtenerTelefono () {
    this.servicio.getPrecioTransporteMax().subscribe(
      res => {
        console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          this.telefono = res['msg']['telefono']
          console.log('telefono :>> ', this.telefono);
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :>> ', err);
      }
    );
  }

  instagram() {
    // location.href = 'https://www.instagram.com/nenechavez/'
    window.open('https://www.instagram.com/nenechavez/')
  }

  facebook() {
    window.open('https://www.facebook.com/NenechavezOficial/')

  }
  whatsapp() {
    window.open('https://api.whatsapp.com/send?phone=+591' + this.telefono + '&text=*Nenechavez.com*%0D%0A%0D%0AHola, %0D%0A¿tienes alguna consulta? no dudes en preguntarnos!')
  }
}
