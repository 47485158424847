import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';

@Component({
  selector: 'app-buscar-correo',
  templateUrl: './buscar-correo.component.html',
  styleUrls: ['./buscar-correo.component.scss']
})
export class BuscarCorreoComponent implements OnInit {
  correo: string = '';
  msj: string = '';
  mostrarError: number = 0;
  loading: boolean = false;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService
  ) {
    this.ls.publishSomeDataNav(false)
   }
  ngOnInit() {
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  getbuscarCorreo() {
    if(this.correo == '' ) {
      this.msj = 'Error de formato de correo'
    }else {
      // //console.log('buscar :>> ', );
      // //console.log('this.correo :>> ', this.correo);
      let obj = {};
      obj['correo'] = this.correo;
      this.loading = true;
      this.servicio.olvidepass(obj).subscribe(
        res => {
          // //console.log('res :', res);
          if (res['errorCode'] == 0) {
            this.mostrarError = 2;
            this.loading = false;
            
          } else if (res['errorCode'] == 2) {
            // this.ls.showToast('warning', 'Alerta!', 'Cupon no disponible' )
            this.mostrarError = 1;
            this.loading = false;
  
          } else if (res['errorCode'] == 3) {
            this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio, intentalo mas tarde' )
            this.loading = false;
            
          }
        },
        err => {
          // //console.log('err :', err);
          this.mostrarError = 1;
          this.loading = false;

          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
    }
  }

}
