import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { Location } from '@angular/common';
import { ServicioService } from 'src/app/servicios/servicio.service';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {
  cliente: any;
  productoCarrito: any = [];
  vacio: boolean = false;
  total: number = 0;
  subTotal: number = 0;
  transporte: number = 0;
  totalPagar: number = 0;
  valorCupon: number = 0;
  verPrecioTransporte: boolean = false;
  height: any;
  width: any;
  esMovil: boolean = true;
  titulo: string;
  rangoTransporte: any = {};
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private location: Location,
    private servicio: ServicioService

  ) {
    this.ls.publishSomeDataNav(true)
    this.getScreenSize();

    this.cliente = {};

    // existe carrito
    let carrito = JSON.parse(localStorage.getItem('ecommerce-carrito'));
    if (carrito) {
      if(carrito.length > 0) {
        this.productoCarrito = carrito;
        if(this.productoCarrito[0].market == 1) {
          this.titulo = 'Market'
        } else {
          this.titulo = this.productoCarrito[0].NombreMarcar;
          
        }
        //console.log('this.titulo :>> ', this.titulo);
        this.calcularTotal();
        // //console.log('this.productoCarrito :', this.productoCarrito);
      } else {
      this.vacio = true;

      }
    } else {
      // //console.log('no existe carrito.');
      this.vacio = true;
    }

    this.getPrecioMax();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.width <= 600) {
      this.esMovil = true;
    } else {
      this.esMovil = false;

    }
    // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  ngOnInit() {
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if (cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);

    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);

    }
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  back() {
    this.location.back();
  }

  getPrecioMax() {
    this.servicio.getPrecioTransporteMax().subscribe(
      res => {
       // //console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          this.rangoTransporte = res['msg']
          
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      }, 
      err => {
        //console.log('err :>> ', err);
      }
    );
  }


  comprar() {
    if (this.cliente.idCliente) {
      // //console.log('this.productoCarrito :>> ', this.productoCarrito);
      localStorage.setItem('ecommerce-carrito', JSON.stringify(this.productoCarrito));
      this.nav.navigateByUrl('/compra')
    } else {
      localStorage.setItem('rutaAnterior-e-commerce', '/compra');
      this.nav.navigateByUrl('/login')
    }
  }
  restar(iMarca, jProducto) {
    // //console.log('restar', iMarca, jProducto);
    if (this.productoCarrito[iMarca].productos[jProducto].cantidad > 0) {

      this.productoCarrito[iMarca].productos[jProducto].cantidad--;
      this.calcularTotal();
    } else {
      // //console.log('cantidad = 0');
    }
  }
  sumar(iMarca, jProducto) {
    // //console.log('sumar', iMarca, jProducto);
    this.productoCarrito[iMarca].productos[jProducto].cantidad++;
    this.calcularTotal();
  }
  calcularTotal() {
    let total = 0;
    let sumaSubTotal= 0;
    for (let i = 0; i < this.productoCarrito.length; i++) {
      for (let j = 0; j < this.productoCarrito[i].productos.length; j++) {
        if (this.productoCarrito[i].productos[j].idCupon > 0) {
          let precioDescuento = 0;
          let totalProducto = 0;
          if (this.productoCarrito[i].productos[j].cupon.idTipoCupon == 1) {
            
            // //console.log('this.getDescuentoPorcentaje :>> ', this.getDescuentoPorcentaje(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total));
            precioDescuento = this.getDescuentoPorcentaje(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total) 
            totalProducto = this.productoCarrito[i].productos[j].cantidad * precioDescuento
            total = total + totalProducto;
            this.productoCarrito[i].productos[j].precioCupon = precioDescuento;
          } else if (this.productoCarrito[i].productos[j].cupon.idTipoCupon == 2) {
            // //console.log('this.getDescuentoMonto :>> ', this.getDescuentoMonto(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total));
            precioDescuento = this.getDescuentoMonto(this.productoCarrito[i].productos[j].precio, this.productoCarrito[i].productos[j].cupon.total)
            totalProducto = this.productoCarrito[i].productos[j].cantidad * precioDescuento
            total = total + totalProducto;
            this.productoCarrito[i].productos[j].precioCupon = precioDescuento;
          }
        } else {
          let totalProducto = this.productoCarrito[i].productos[j].cantidad * this.productoCarrito[i].productos[j].precio;
          total = total + totalProducto;
        }
        // calculo subTotal
        let subTotal = this.productoCarrito[i].productos[j].cantidad * this.productoCarrito[i].productos[j].precio;
        sumaSubTotal = sumaSubTotal + subTotal;

      }
    }
    // //console.log('total :>> ', total);
    this.total = total;
    this.total = +( this.total.toFixed(2))

    this.totalPagar = this.total + this.transporte;
    this.totalPagar = +( this.totalPagar.toFixed(2))

    this.subTotal = sumaSubTotal;
    this.subTotal = +( this.subTotal.toFixed(2))

    this.valorCupon = this.subTotal - this.total;
    this.valorCupon = +( this.valorCupon.toFixed(2))

   // //console.log('this.productoCarrito :>> ', this.productoCarrito);
  }

  getDescuentoPorcentaje(precio: number, descuento: number): number {
    descuento = descuento / 100;
    descuento = precio * descuento
    let a = precio - descuento
    a =+( a.toFixed(2))
    // //console.log('a :>> ', a);
    return a;
  }
  getDescuentoMonto(precio: number, descuento: number): number {
    descuento = precio - descuento
    return descuento;
  }

  verPrecioT() {
    if (this.verPrecioTransporte == false) {
      this.verPrecioTransporte = true;
    } else {
      this.verPrecioTransporte = false;
    }
  }

  eliminarProductoCarrito(i, j) {
    //.log('i,j :>> ', i, j);
    ////console.log('object :>> ', this.productoCarrito[i].productos[j]);
    this.productoCarrito[i].productos.splice(j, 1)
    this.ls.publishSomeDataCarrito(-1);
    if (this.productoCarrito[i].productos.length == 0) {
      this.productoCarrito.splice(i, 1);
      if (this.productoCarrito.length == 0) {
        this.productoCarrito = [];
        this.vacio = true;

      }
    }
    localStorage.setItem("ecommerce-carrito", JSON.stringify(this.productoCarrito));

  }
  eliminarCarrito() {
    // //console.log('vaciar carrito :>> ');
    this.productoCarrito = [];
    localStorage.setItem("ecommerce-carrito", JSON.stringify(this.productoCarrito));
    this.ls.publishSomeDataCarrito(0);
    this.vacio = true;
    this.titulo = undefined;
  }

}
