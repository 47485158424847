import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from 'src/app/servicios/servicio.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-comercio',
  templateUrl: './comercio.component.html',
  styleUrls: ['./comercio.component.scss']
})
export class ComercioComponent implements OnInit {
  cliente: any;
  idMarca: any;
  productos: any= [];
  marca: any = {};
  sinProductos:boolean=true;
  loadingSugeridos: any = true;
  marcaLoading: any = true;
  pos: number = 0;
  limite: number = 9;
  vacio: boolean = false;
  horario: any ={};
  height:any;
  width:any;
  esMovil:boolean = true;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private activatedRoute: ActivatedRoute,
    private location: Location

  ) {
    this.cliente = {};
    this.ls.publishSomeDataNav(true)
    this.getScreenSize();

   }

   @HostListener('window:resize', ['$event'])
   getScreenSize(event?) {
         this.height = window.innerHeight;
         this.width = window.innerWidth;
         if(this.width<=600){
           this.esMovil=true;
         }else{
           this.esMovil=false;

         }
        // // //console.log('height: '+this.height,'width: '+ this.width);
   }
  ngOnInit() {

    this.idMarca = +(this.activatedRoute.snapshot.paramMap.get('idMarca'));


    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if(cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
    this.getDetalleMarca();
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  back() {
    this.location.back();
  }
  getDetalleMarca() {
    this.marcaLoading = true;

    this.servicio.getDetalleMarca(this.idMarca).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.marca = res['msg'];
          this.marcaLoading = false;
          this.getListaProductosMarca();
          this.getHorarioAtencion();
        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  getListaProductosMarca() {
    this.pos = 0;
    this.limite = 9;
    this.servicio.getListaProductosMarca(this.idMarca, this.pos, this.limite).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {

          this.productos = res['msg'];
          this.loadingSugeridos = false;
        } else if (res['errorCode'] == 2) {
          // this.sinProductos=false;
          this.loadingSugeridos = false;
          this.vacio=true;


        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }
  getHorarioAtencion() {
    ////console.log('this.producto.idMarca :>> ', this.marca.idMarca);
    this.servicio.getHorarioAtencion(this.marca.idMarca).subscribe(
      res => {
        console.log('res :', res);
        if (res['errorCode'] == 0) {
          let horarios: any = [];
          horarios = res['msg']
          let dia = moment(new Date()).format("E")
          for (let i = 0; i < horarios.length; i++) {
            if(horarios[i].idDia == dia) {
              this.horario = horarios[i]
             console.log('this.horario :>> ', this.horario);
              return;
            }
          }
          // //console.log('dia :>> ', dia);
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        }
      },
      err => {
        this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio')
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    )
  }

  detalleProducto(idProducto) {
    this.nav.navigateByUrl('detalle-producto/'+idProducto);
  }
  infinitProductosComercio(event) {
      this.pos = this.pos + this.limite;
      this.servicio.getListaProductosMarca(this.idMarca, this.pos, this.limite).subscribe(
        res => {
          event.target.complete();

          // //console.log('res :', res);
          if (res['errorCode'] == 0) {

            let productos = res['msg'];
            for (let i = 0; i < productos.length; i++) {
              this.productos.push(productos[i]);
            }
          } else if (res['errorCode'] == 2) {
            this.vacio=true;
          } else if (res['errorCode'] == 3) {

          }
        },
        err => {
          event.target.complete();
          // //console.log('err :', err);
          // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
        }
      );
  }
}
