import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { MenuItem, } from 'primeng/api';
import { ServicioService } from '../../servicios/servicio.service';
import { Location } from '@angular/common';
import { Subject, Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-estado-compra',
  templateUrl: './estado-compra.component.html',
  styleUrls: ['./estado-compra.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EstadoCompraComponent implements OnInit {
  total: number = 0;
  transporte: number = 0;
  totalPagar: number = 0;
  verPrecioTransporte: boolean = false;
  estados: any = [];
  ListaEstados: any = [];
  idEstado: number;
  cliente: any;
  detallePedido: any = [];
  limite: number = 10;
  pos: number = 0;
  idPedido: number;
  pedido: any = {
    precioTransporte: 0
  };
  height:any;
  width:any;
  esMovil:boolean = true;
  rangoTransporte: any = {};
  pedidoCancelado: boolean = false;

  obserEstado: boolean = false;
  private observable: any;
  private _subscription: Subscription;

  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private activatedRoute: ActivatedRoute,
    private location: Location,

  ) {
    this.ls.publishSomeDataNav(true);
    this.getScreenSize();

    this.cliente = {};
    this.getPrecioMax();

    this.activarInterval();

  }

  activarInterval() {
    if (this.obserEstado == false) {
      this.obserEstado = true;
      // this.mostrarLoading('Conectando')
      this.observable = interval(30000);
      this._subscription = this.observable.subscribe(n => {
         this.actualizarPedido();
      });
    } else {
      // //console.log('this.ls.obserEstado :', this.obserEstado, '---> ');
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        if(this.width<=600){
          this.esMovil=true;
        }else{
          this.esMovil=false;

        }
       // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  ngOnInit() {
    this.idPedido = +(this.activatedRoute.snapshot.paramMap.get('idPedido'));

    this.ListaEstados = [
      { id:1, label: 'Pedido Recibido', icon: 'fa fa-check-circle' },
      { id:2, label: 'Preparando Pedido',icon: 'fa fa-check-circle'},
      { id:3, label: 'Pedido en Camino' ,icon: 'fa fa-check-circle'},
      { id:4, label: 'Pedido Entregado' ,icon: 'fa fa-check-circle'}
    ];
    this.idEstado = 1;
    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if(cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente);
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente);
    }
    this.getPedido();
    this.getDetalleEstado();
    this.scrollTop();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._subscription.unsubscribe();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  back() {
    this.location.back();
  }

  getPrecioMax() {
    this.servicio.getPrecioTransporteMax().subscribe(
      res => {
        ////console.log('res :>> ', res);
        if (res['errorCode'] == 0) {
          this.rangoTransporte = res['msg']

        } else if (res['errorCode'] == 2) {

        } else if (res['errorCode'] == 3) {

        }
      },
      err => {
        //console.log('err :>> ', err);
      }
    );
  }

  actualizarPedido() {
    this.estados = [];
    this.getPedido();
  }
  getPedido() {
    this.servicio.getPedido(this.idPedido,this.cliente.idCliente, this.cliente.token).subscribe(
      res => {
       console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.pedido = res['msg'][0]
            if(this.pedido.idEstadoPedido == 1) {
              this.estados.push(this.ListaEstados[0]);
            } else if(this.pedido.idEstadoPedido == 2 ) {
              this.estados.push(this.ListaEstados[0]);
              this.estados.push(this.ListaEstados[1]);
            } else if(this.pedido.idEstadoPedido == 3)   {
              this.estados.push(this.ListaEstados[0]);
              this.estados.push(this.ListaEstados[1]);
              this.estados.push(this.ListaEstados[2]);
            }  else if(this.pedido.idEstadoPedido == 4)   {
              this.estados.push(this.ListaEstados[0]);
              this.estados.push(this.ListaEstados[1]);
              this.estados.push(this.ListaEstados[2]);
              this.estados.push(this.ListaEstados[3]);
            } else if(this.pedido.idEstadoPedido == 5) {
              this.pedidoCancelado = true;
            }
          this.totalPagar = (+this.pedido.total) + (+this.pedido.precioTransporte)
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio' )
        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getDetalleEstado() {
    // //console.log('this.cliente :>> ', this.cliente);
    // //console.log('this.idPedido :>> ', this.idPedido);
    this.servicio.getDetallePedidoMarcaCli(this.idPedido,this.cliente.idCliente, this.cliente.token).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.detallePedido = res['msg']

        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio' )
        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    )
  }

  calcularTotal() {
    let total = 0;
    for (let i = 0; i < this.detallePedido.length; i++) {
      for (let j = 0; j < this.detallePedido[i].productos.length; j++) {
        let totalProducto = this.detallePedido[i].productos[j].cantidad * this.detallePedido[i].productos[j].precio;
        total = total + totalProducto;
      }
    }
    // //console.log('total :>> ', total);
    this.total = total;
    this.totalPagar = this.total + this.transporte;
  }
  verPrecioT() {
    if(this.verPrecioTransporte == false) {
      this.verPrecioTransporte = true;
    } else {
      this.verPrecioTransporte = false;
    }
  }

}
