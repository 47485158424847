import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy, HostListener } from '@angular/core';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { Subscription } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';

import { NbMenuService, NbThemeService, NB_WINDOW, NbMenuComponent } from '@nebular/theme';



import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'angularx-social-login';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  mostrarModal: boolean = false;
  cliente: any = {
    ver: false
  };
  suscripcion: Subscription;
  userMenu = [{ title: 'Mi Perfil' }, { title: 'Salir' }];
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  verBuscar: boolean = true;
  show:boolean=true;

  height:any;
  width:any;
  esMovil:boolean=true;

  texto: string = '';
  constructor(
    private authService: AuthService,
    private nav: Router,
    private ls: ServicioLocalService,
    private cdRef: ChangeDetectorRef,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
     @Inject(NB_WINDOW) private window,
  ) {
    // //console.log('navbar');
    this.getScreenSize();

    this.suscripcion = this.ls.getObservableCliente().subscribe((data) => {
      this.cliente = data;
      this.cdRef.detectChanges();
      // //console.log('Data cliente', this.cliente);
    });
  }
 

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        if(this.width<=1000){
          this.esMovil=true;
        }else{
          this.esMovil=false;
          
        }
       // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  ngOnInit() {

    // nebular

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Salir') {
          localStorage.removeItem('cliente-e-commerce')
          this.nav.navigate(['/login']);
        } if(title == 'Mi Perfil') {
          this.nav.navigate(['/perfil']);

        }else {

        }
      });
  }
  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  navegar(ruta) {
    this.toggle();

    this.nav.navigateByUrl(ruta);
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  buscar() {
    this.nav.navigateByUrl('/buscar');
  }

  toggle() {
    if(this.show == true) { 
      this.show = false;
    } else { 
      this.show = true;
    }
    // CHANGE THE NAME OF THE BUTTON.
  }
  navegarLogin() {
    localStorage.removeItem('cliente-e-commerce');
    this.nav.navigateByUrl('/inicio')
    this.mostrarModal = false;
    ////asdfasdf
    this.authService.signOut();
    setTimeout(() => {
      location.reload()
    }, 1000);
  }
  cerrarSesion() {

    this.mostrarModal = true;
  }

  enviarBuscador(ev) {
    // //console.log('ev :>> ', ev);
    if(this.texto.length > 3) {
      this.ls.publishSomeDataBuscador(this.texto);
    } else if(this.texto.length == 0) {
      this.ls.publishSomeDataBuscador(this.texto);

      // //console.log('length menor');
    }
  }


}
