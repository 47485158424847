import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(value: string, args) {
    moment.locale('es');
    var a ='';
    if(args == "long"){
       a = moment(value).format('dddd, D MMMM');
    }else if(args == "short"){
       a = moment(value).format('D/MMM/YYYY H:mm');
    }else if(args == "hora"){
     // //console.log('value :>> ', value);
       a = moment(value).format('hh:mm');
   //    //console.log('a :>> ', a);
    }else if(args == "minutos"){
       var cad = "-";
       if(value == undefined){
         a = cad;
       }else{
          a = value.substr(3,2);
       }
       
    }else if(args == "horacustom"){
      value = '2018/12/12 ' + value;
       a = moment(value).format('H:mm');
    }else if(args == "dia"){
       a = moment(value).format('D');
    }else if(args == "mes"){
       a = moment(value).format('MMM');
    }else if(args == "mescorto"){
      a = moment(value).format('M');
    }else if(args == "mes2digitos"){
      a = moment(value).format('MM');
    }else if(args == "dia2digitos"){
      a = moment(value).format('DD');
    }
    
    return a;
  }

}
