import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FormsModule } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import {EditorModule} from 'primeng/editor';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';

// import { NbThemeModule, NbLayoutModule, NbMenuComponent } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbCardModule,
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbThemeModule,
  NbLayoutModule,
  NbPopoverModule,
  NbUserModule,
  NbContextMenuModule,
  NbActionsModule,
  NbMenuService,
  NbMenuComponent,
  NbSelectModule,
  NbRadioModule,
} from '@nebular/theme';
import { LoginComponent } from './paginas/login/login.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { BuscarCorreoComponent } from './paginas/buscar-correo/buscar-correo.component';
import { CambiarClaveComponent } from './paginas/cambiar-clave/cambiar-clave.component';
import { ComprasRecientesComponent } from './paginas/compras-recientes/compras-recientes.component';
import { DetalleCompraComponent } from './paginas/detalle-compra/detalle-compra.component';
import { PerfilComponent } from './paginas/perfil/perfil.component';
import { DetalleProductoComponent } from './paginas/detalle-producto/detalle-producto.component';
import { ComercioComponent } from './paginas/comercio/comercio.component';
import { CarritoComponent } from './paginas/carrito/carrito.component';
import { CompraComponent } from './paginas/compra/compra.component';
import { EstadoCompraComponent } from './paginas/estado-compra/estado-compra.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { RegistroClienteComponent } from './paginas/registro-cliente/registro-cliente.component';
import { BotonCarritoComponent } from './componentes/boton-carrito/boton-carrito.component';
import { ContactoComponent } from './paginas/contacto/contacto.component';
import { TerminosComponent } from './paginas/terminos/terminos.component';

import { GalleriaModule } from 'primeng/galleria';
import { StepsModule } from 'primeng/steps';
import { MenuModule } from 'primeng/menu';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { AgmCoreModule } from '@agm/core';
import { BuscarComponent } from './paginas/buscar/buscar.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from './pipes/pipes.module';
import { FooterComponent } from './componentes/footer/footer.component';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { TextMaskModule } from 'angular2-text-mask';

// import {IvyGalleryModule} from 'angular-gallery';
// import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { NgxGalleryModule } from 'ngx-gallery';
import 'hammerjs';
import { GaleriaComponent } from './paginas/galeria/galeria.component';
import { ModalPagoComponent } from './componentes/modal-pago/modal-pago.component';
import { PopoverRetrasoComponent } from './componentes/popover-retraso/popover-retraso.component';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("579758592135-dhbd5j82df8kium8lnlthaiu2esikhae.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("251096732624491")
  }
]);

export function provideConfig() {
  return config;
}
@NgModule({

  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    BuscarCorreoComponent,
    CambiarClaveComponent,
    ComprasRecientesComponent,
    DetalleCompraComponent,
    PerfilComponent,
    DetalleProductoComponent,
    ComercioComponent,
    CarritoComponent,
    CompraComponent,
    EstadoCompraComponent,
    NavbarComponent,

    RegistroClienteComponent,
    BotonCarritoComponent,
    ContactoComponent,
    TerminosComponent,
    BuscarComponent,
    FooterComponent,
    GaleriaComponent,
    ModalPagoComponent,
    PopoverRetrasoComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule ,
    NgxGalleryModule,
    //PrimeNG
    ScrollPanelModule,
    GalleriaModule,
    MenuModule,
    StepsModule,
    FileUploadModule,
    CarouselModule,
    CalendarModule,
    DialogModule,
    ProgressSpinnerModule,
    //---------
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // FormsModule,
    HttpClientModule,
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    //nebular
    NbSelectModule,
    NbChatModule,
    NbDialogModule,
    NbSidebarModule,
    NbCardModule,
    NbPopoverModule,
    NbWindowModule,
    NbToastrModule.forRoot(),
    NbRadioModule,
    NbAlertModule,
    NbSpinnerModule,
    NbUserModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbContextMenuModule,
    NbActionsModule,
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),

    // MAPS
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyBOeQnqil8oVfgQODwdd9gatNj-Snuxw0I',
    }),
    IonicModule.forRoot(),

    PipesModule,
    SocialLoginModule
  ],
  providers: [
    NbMenuService,
    PipesModule,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  entryComponents: [
    GaleriaComponent,
    ModalPagoComponent,
    PopoverRetrasoComponent,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
