import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ServicioService } from '../../servicios/servicio.service';
import * as moment from 'moment';
import { FormGroup,FormControl, Validators, AbstractControl } from '@angular/forms';

import { luhnValidator } from '../../validators/luhnValidator';
import { getValidationConfigFromCardNo } from '../../helpers/card.helper';

@Component({
  selector: 'app-modal-pago',
  templateUrl: './modal-pago.component.html',
  styleUrls: ['./modal-pago.component.scss']
})
export class ModalPagoComponent implements OnInit {
  @Input() tarjeta;

  cardNumberGroup: FormGroup;

  texto: string;

  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskAnio = [ /\d/, /\d/, /\d/, /\d/];
  public maskmes = [ /[0-1]/, /\d/];
  public maskcvv = [ /\d/, /\d/, /\d/];

  constructor(
    private modalController: ModalController,
    private servicio: ServicioService,
  ) {
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl('', [
              Validators.required,
              Validators.minLength(12),
              luhnValidator()
            ]),

      // cvv: new FormControl('', [
      //         Validators.required,
      //         Validators.minLength(3)
      //       ])
    });

  }

  ngOnInit() {
    //console.log('this.tarjeta :>> ', this.tarjeta);
  }
  cerrar() {
    this.modalController.dismiss({ res: false })
  }
  finalizar() {

    // console.log(this.cardNumberGroup);
    // console.log(this.cardNumberGroup.get('cardNumber').value);
    let numerotarjeta : string = this.cardNumberGroup.get('cardNumber').value;
    console.log(numerotarjeta.replace(/\s/g,''));
    numerotarjeta = numerotarjeta.replace(/\s/g,'');

    this.tarjeta.nroTarjeta = numerotarjeta;

    this.texto = undefined;
    let valido = this.validarDatos(this.tarjeta);
    if (valido) {
      //console.log('this.tarjeta :>> ', this.tarjeta);
      let obj = {};
      obj['fecha_expiracion'] = +(this.tarjeta.anho + this.tarjeta.mes)
      obj['anho'] = this.tarjeta.anho
      obj['mes'] =  this.tarjeta.mes
      obj['tarjeta'] = this.tarjeta.nroTarjeta;
      obj['nroTarjeta'] = this.tarjeta.nroTarjeta;
      obj['nombre_cliente'] = this.tarjeta.nombre;
      obj['nombre'] = this.tarjeta.nombre;
      // obj['fecha_expiracion'] = '202201'
      // obj['tarjeta'] = '4560160018692018';
      // obj['nombre_cliente'] = 'Cliente prueba';
      obj['cvv2'] = this.tarjeta.cvv;
      obj['cvv']= this.tarjeta.cvv;
      // obj['secuencia'] = '000012';
      // obj['monto'] = '00 00 00 01 00 00';
      let d = moment().format('DD');
      let m = moment().format('MM');
      let a = moment().format('YYYY');
      //console.log(' a+m+d :>> ', a + m + d);
      let h = moment().format('HH');
      let min = moment().format('mm');
      //console.log(' h+min :>> ', h + min);
      obj['fecha_envio'] = a + m + d;
      obj['hora_envio'] = h + min + '00';

      console.log(obj);

      this.modalController.dismiss({res: true, datos: obj} )
    } else {
      // this.texto = valido;
    }

  }
  validarDatos(tarjeta) {
    if (tarjeta.nombre == undefined || tarjeta.nombre == '') {
      return 'Campo requerido Nombre del titular';
    } else if (tarjeta.nroTarjeta == undefined || tarjeta.nroTarjeta < 999999999999) {
      return 'Campo requerido Numero de tarjeta, verificar  digitos';
    } else if (tarjeta.mes == undefined || tarjeta.direccion == '') {
      return 'Campo requerido Mes de exporación';
    } else if (tarjeta.anho == undefined) {
      return 'Campo requerido año de exipración';
    } else if (tarjeta.cvv == undefined) {
      return 'Campo requerido Codigo';
    }
    return true;
  }

  getCardNumberControl(): AbstractControl | null {
    let valor =  this.cardNumberGroup && this.cardNumberGroup.get('cardNumber');
    console.log(valor.status);
    return valor;
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }
}
