import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicioLocalService } from '../../servicios/servicio-local.service';
import { ServicioService } from '../../servicios/servicio.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detalle-compra',
  templateUrl: './detalle-compra.component.html',
  styleUrls: ['./detalle-compra.component.scss']
})
export class DetalleCompraComponent implements OnInit {
  cliente: any;
  detallePedido: any = [];
  pedido: any = {
    precioTransporte: 0
  };
  verPrecioTransporte = false;
  idPedido: number;
  total: number = 0;
  transporte: number = 0;
  totalPagar: number = 0;
  height:any;
  width:any;
  esMovil:boolean = true;
  constructor(
    private nav: Router,
    private ls: ServicioLocalService,
    private servicio: ServicioService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.getScreenSize();

    this.ls.publishSomeDataNav(true);
    this.cliente = {};
    this.idPedido = +(this.activatedRoute.snapshot.paramMap.get('idPedido'));

   }
   @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        if(this.width<=600){
          this.esMovil=true;
        }else{
          this.esMovil=false;
          
        }
       // // //console.log('height: '+this.height,'width: '+ this.width);
  }
  ngOnInit() {

    let cliente = JSON.parse(localStorage.getItem('cliente-e-commerce'));
    if(cliente) {
      this.cliente = cliente;
      this.cliente.ver = true;
      // //console.log('this.cliente :>> ', this.cliente);
      this.ls.publishSomeDataCliente(this.cliente); 
    } else {
      // //console.log('no existe cliente');
      this.cliente.ver = false;
      this.ls.publishSomeDataCliente(this.cliente); 
    }

    this.getPedido();
    this.getDetalleEstado();
    this.scrollTop();
  }
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  back() {
    this.location.back();
  }

  getPedido() {
    this.servicio.getPedido(this.idPedido,this.cliente.idCliente, this.cliente.token).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.pedido = res['msg'][0]

          this.totalPagar = (+this.pedido.total) + (+this.pedido.precioTransporte)
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio' )
        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    );
  }

  getDetalleEstado() {
    // //console.log('this.cliente :>> ', this.cliente);
    // //console.log('this.idPedido :>> ', this.idPedido);
    this.servicio.getDetallePedidoMarcaCli(this.idPedido,this.cliente.idCliente, this.cliente.token).subscribe(
      res => {
        // //console.log('res :', res);
        if (res['errorCode'] == 0) {
          this.detallePedido = res['msg']
          
        } else if (res['errorCode'] == 2) {
          // this.ls.showToast('warning', 'Alerta!', 'No hay datos' )

        } else if (res['errorCode'] == 3) {
          this.ls.showToast('danger', 'Error!', 'ocurrio un error en el servicio' )
        }
      },
      err => {
        // //console.log('err :', err);
        // this.mostrarAlert('Error!', 'Ocurrio un error', 'Revisa tu conexion a internet y vuelve a intentarlo en unos minutos')
      }
    )
  }
  verPrecioT() {
    if(this.verPrecioTransporte == false) {
      this.verPrecioTransporte = true;
    } else {
      this.verPrecioTransporte = false;
    }
  }

}
